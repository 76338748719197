import React, { PureComponent } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

import { Section, Container, Image, Flex } from "../../atoms"
import VideoModal from "../VideoModal"
import Slider from "./Slider"
import Buttons from "./Buttons"
import Phone from "./Phone"
import satiLogo from "../../../assets/images/sati_logo.png"

class Header extends PureComponent {
  state = {
    animateButton: false,
    videoShown: false,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animateButton: true })
    }, 4666)
  }

  toggleVideo = () => {
    this.setState((st) => ({ videoShown: !st.videoShown }))
  }

  render() {
    const { t } = this.props
    return (
      <CustomSection>
        <Container>
          <HeaderWrap>
            <HeaderContent direction={"column"} alignItems={"center"}>
              <HeaderLogo src={satiLogo} alt={t('Header.logoAlt')} />
              <Slider />
              <Buttons />
            </HeaderContent>
            <Phone />
          </HeaderWrap>
        </Container>
        {this.state.videoShown && <VideoModal onClose={this.toggleVideo} />}
      </CustomSection>
    )
  }
}

const CustomSection = styled(Section)`
  position: relative;
  min-height: 740px;
  margin-bottom: 90px;
  padding: 0;
  ${(p) => p.theme.min("xxl")`
        margin-bottom: 250px;
  `}
  ${(p) => p.theme.max("md")`
     margin-bottom: 0;
     min-height: 533px;
   `}
   ${(p) => p.theme.min("md")`
      min-height: 49.6vw;
    `}
`

const HeaderWrap = styled(Flex)`
  position: relative;
  z-index: ${(p) => p.theme.zIndex.header};
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  ${p => p.theme.min('md')`
    padding-right: 50px;
  `}
  ${p => p.theme.max('md')`
    height: 90vh;
  `}
`

const HeaderContent = styled(Flex)`
  ${(p) => p.theme.max("md")`
        padding-top: 40px;
        padding-left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
    `}
  ${(p) => p.theme.max("sm")`
      padding: 0 20px;
    `}
`

const HeaderLogo = styled(Image)`
  width: 169px;
  margin-top: -59px;
  ${(p) => p.theme.between("sm", "xl")`
      width: 11.3vw;
      margin-top: -69px;
    `}
  ${(p) => p.theme.max("md")`
        width: 180px;
    `}
    ${(p) => p.theme.max("sm")`
        margin-top: 49px;
    `}
    ${p => p.theme.max('xs')`
      margin-top: -6vw;
      width: 43vw;
    `}
`

export default withTranslation()(Header)
