import React from "react"
import styled, { css } from "styled-components"
import Container from "./Container"

const FeatureItem = ({ screenshot, className, forwardedRef, children, flip, negativeMargin }) => {
  return (
    <CustomSection negativeMargin={negativeMargin} ref={forwardedRef}>
      <Container>
        <ItemCont flip={flip} className={className}>
          <div>{screenshot}</div>
          <div>{children}</div>
        </ItemCont>
      </Container>
    </CustomSection>
  )
}

const CustomSection = styled.div`
  position: relative;
  padding: 50px 0;
  ${p => p.negativeMargin && css`
    ${p => p.theme.min('md')`
      margin-top: -119px;
    `}
  `}
  ${p => p.theme.max('sm')`
    padding: 0 0 90px;
  `}
`;

const ItemCont = styled.div`
  display: flex;
  width: 100%;
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${p => p.theme.max('sm')`
      width: 100%;
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        margin-bottom: 16px;
      }
    `}
    &:nth-child(1) {
      align-items: center;
      > div {
        ${p => p.theme.min('md')`
          position: relative;
          left: -39px;
        `}
      }
    }
    &:nth-child(2) {
      ${p => p.theme.min('sm')`
        padding-right: 50px;
      `}
      ${p => p.theme.max('sm')`
        padding: 0 ${p => p.theme.general.mobilePadding};
        text-align: center;
      `}
    }
    ${p => p.flip && css`
      ${p => p.theme.min('sm')`
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: 80px;
          > p {
            text-align: right;
          }
        }
      `}
    `}
  }
  ${p => p.theme.max('sm')`
    flex-direction: column;
  `}
`

export default FeatureItem
