import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { PlayerProvider } from '../../api/player';

import SatiLanding from '../pages/SatiLanding';
import theme from '../theme';
import GlobalStyles from '../GlobalStyles';

class AppRoot extends PureComponent {
    render() {
        return (
            <ThemeProvider theme={theme}>
              <PlayerProvider>
                <div>
                    <GlobalStyles/>
                    <SatiLanding/>
                </div>
              </PlayerProvider>
            </ThemeProvider>
        );
    }
}

export default AppRoot;