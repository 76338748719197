import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, Text } from '../../atoms';

const SoundModal = ({ item: { heading, text }, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Modal {...rest}>
      <Heading>{t(heading)}</Heading>
      <Text>{t(text)}</Text>
    </Modal>
  )
};

const Heading = styled(Text)`
  && {
    font-size: 22px;
  }
  font-weight: 700;
  margin-top: 0;
`

export default SoundModal;
