import React from "react"
import styled from "styled-components"

import { FeatureItemScreenshot } from "core/ui/atoms"
import { getLocale } from "../../../../i18n"
import SCREENSHOT from "../../../assets/images/screenshot_history.png"
import SCREENSHOT_6_EN from "../../../assets/images/screenshot_history_6_en.png"
import SCREENSHOT_5_EN from "../../../assets/images/screenshot_history_5_en.png"
import SCREENSHOT_4_EN from "../../../assets/images/screenshot_history_4_en.png"
import SCREENSHOT_3_EN from "../../../assets/images/screenshot_history_3_en.png"
import SCREENSHOT_2_EN from "../../../assets/images/screenshot_history_2_en.png"
import SCREENSHOT_1_EN from "../../../assets/images/screenshot_history_1_en.png"
import SCREENSHOT_6_RU from "../../../assets/images/screenshot_history_6_ru.png"
import SCREENSHOT_5_RU from "../../../assets/images/screenshot_history_5_ru.png"
import SCREENSHOT_4_RU from "../../../assets/images/screenshot_history_4_ru.png"
import SCREENSHOT_3_RU from "../../../assets/images/screenshot_history_3_ru.png"
import SCREENSHOT_2_RU from "../../../assets/images/screenshot_history_2_ru.png"
import SCREENSHOT_1_RU from "../../../assets/images/screenshot_history_1_ru.png"

const SCREENSHOTS = {
  en: [
    SCREENSHOT_1_EN,
    SCREENSHOT_2_EN,
    SCREENSHOT_3_EN,
    SCREENSHOT_4_EN,
    SCREENSHOT_5_EN,
    SCREENSHOT_6_EN,
  ],
  ru: [
    SCREENSHOT_1_RU,
    SCREENSHOT_2_RU,
    SCREENSHOT_3_RU,
    SCREENSHOT_4_RU,
    SCREENSHOT_5_RU,
    SCREENSHOT_6_RU,
  ],
}

const TOTAL = 900

const HistoryScreenshot = ({ scrollPercent }) => {
  const x = TOTAL * scrollPercent / 100

  return (
    <Cont>
      <FeatureItemScreenshot image={SCREENSHOT} />
      <ScrollCont x={x}>
        <div>
          {SCREENSHOTS[getLocale()].map((src, i) => (
            <DayItem key={`image-${i}`} src={src} />
          ))}
        </div>
      </ScrollCont>
    </Cont>
  )
}

const Cont = styled.div`
  position: relative;
  display: inline-block;
`

const ScrollCont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > div {
    transform: translateY(-${p => p.x}px);
  }
`

const DayItem = styled.img`
  width: 100%;
`

export default HistoryScreenshot
