import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import APPLE_ICON from '../../../assets/images/apple_icon.png';
import PLAYSTORE_ICON from '../../../assets/images/playstore_icon.png';

const GetAppButton = ({
  small,
  isApple,
  isPayment,
  isScrollButton,
  disabled,
  customText,
  customLink,
  goal = null,
}) => {
  const ua = window.navigator.userAgent;
  const isIos = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const { t } = useTranslation();

  const goTo = () => {
    if (isPayment && goal && window.VK && window.VK.Goal) {
      window.VK.Goal('initiate_checkout');
    }
    if (isApple && !isPayment && window.VK && window.VK.Goal) {
      window.VK.Goal('conversion');
    }
    if (customLink) {
      window.location.href = customLink;
      return;
    }
    window.location.href = isPayment
      ? 'https://payment.cielomeditation.ru'
      : isApple
      ? 'https://get.sati.app/1kMP/10b0009c'
      : 'https://play.google.com/store/apps/details?id=com.sati';
  };

  const scrollToBottom = () => {
    if (window && window.scrollTo) {
      window.scrollTo({
        left: 0,
        top: document?.body?.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Button
      isApple={isApple}
      isIos={isIos}
      isPayment={isPayment}
      onClick={disabled ? null : isScrollButton ? scrollToBottom : goTo}
      small={small}
      disabled={disabled}
      className={'initiate-checkout-btn'}
    >
      {!isPayment ? (
        <Icon
          source={isApple ? APPLE_ICON : PLAYSTORE_ICON}
          isApple={isApple}
        />
      ) : null}
      {customText ||
        t(
          isPayment
            ? 'General.purchasePayment'
            : isApple
            ? 'General.downloadAppstore'
            : 'General.downloadPlaystore'
        )}
    </Button>
  );
};

const Button = styled.button`
  position: relative;
  background: white;
  border-radius: 100px;
  padding: 12px 33px 12px 46px;
  width: 275px;
  border: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-family: TT Norms Pro, sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.23);
  ${(p) =>
    p.disabled &&
    css`
      background: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.2);
    `}
  ${(p) => p.theme.max('md')`
    display: ${(p) =>
      (p.isIos && !p.isApple) || (!p.isIos && p.isApple)
        ? p.isPayment
          ? 'inline'
          : 'none'
        : 'inline'};
  `}
  &:hover {
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  }
  ${(p) =>
    p.small &&
    css`
      font-size: 16px;
      width: 235px;
      padding: 8px 24px 8px 38px;
      > div {
        width: 18px;
        height: 18px;
      }
    `}
  ${(p) => p.theme.max('md')`
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  `}
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, ${(p) => (p.isApple ? '-55%' : '-50%')});
  filter: invert(${(p) => (p.isApple ? '100' : '0')}%);
  width: 24px;
  height: 24px;
  background-image: url(${(p) => p.source});
  background-size: cover;
`;

export default GetAppButton;
