export const DAYS = {
  ru: [
    {
      id: "day_1",
      day: 1,
      name: "Осознанность",
      theory:
        "Узнаете научное и понятное обоснование состояния осознанности. Как осознанность меняет жизнь, и как развивать это состояние.",
      practice:
        "Знакомитесь с медитацией осознанности дыхания. Объяснение базовой техники и сути благотворного воздействия медитации.",
      integration:
        "Анализируете свое состояние до и после медитации, и записываете свои наблюдения.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_2",
      day: 2,
      name: "Медитация",
      theory: "Поймете суть медитации. Узнаете о различных ее видах и техниках. Поймете, какие из них являются самыми эффективными.",
      practice: "Продолжаете формировать базовый навык медитации через осознанность дыхания, постепенно расширяя практику.",
      integration: "Учитесь использовать концентрацию на дыхании для работы с негативными переживаниями в течение дня",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_3",
      day: 3,
      name: "Беспокойный ум",
      theory: "Узнаете зачем и почему существует стресс. Как он работает в вашем теле и как от него освободиться.",
      practice: "Продолжаем отрабатывать базовый навык медитации, немного удлинняя практику и изучая новые приемы.",
      integration: "Учитесь использовать концентрацию в жизни для эффективной работы со стрессовыми состояниями.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_4",
      day: 4,
      name: "Чистый ум",
      theory: "Узнаете, к какому состоянию ума вы придете при постоянной практиковать медитацию. Научное обоснование осознанности и собранного, гармоничного ума.",
      practice: "Немного удлиняете и расширяете практику осознанности дыхания. Первые результаты.",
      integration: "Комбинируете концентрацию на дыхании и осознание эмоций, для того чтобы гармонизировать свое состояние в течение дня.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_5",
      day: 5,
      name: "Эго",
      theory: "Как наш мозг создает упрощенную версию себя самого. Из чего она состоит. Как мешает нам наслаждаться жизнью, и как освободиться от контроля эго",
      practice: "Продолжаете формировать базовый навык медитации и гармонизируете ум с помощью медитации анапанасати",
      integration: "Продолжаете осознавать свои переживания в течение дня, но особое внимание обращаете на работу эго-конструкций",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_6",
      day: 6,
      name: "Колесо самсары",
      theory: "Работа дофаминовой системы. Узнаете, почему колесо Самсары - это не про перерождение, а про зависимости. Почему эго не может быть удовлетворено.",
      practice: "Продолжаете формировать базовый навык медитации и углубляете концентрацию с помощью медитации анапанасати.",
      integration: "Пробуете сохранять состояние баланса ума в течение всего дня с помощью особых техник.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_7",
      day: 7,
      name: "Карма",
      theory: "В чем действительный корень наших действий. Как по-настоящему работает карма, и почему в этом нет никакой эзотерики.",
      practice: "Продолжаете формировать базовый навык сконцентрированной медитации с помощью практики осознанности дыхания.",
      integration: "Переносите контоль внимания с дыхания на свои повседевные дела, и анализируете, как меняются обстоятельства.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_8",
      day: 8,
      name: "Пробуждение",
      theory: "Суть пробуждения без воды и эзотерики. Научноый фундамент пробуждения. Духовная эволюция.",
      practice: "Расширяете практику с помощью периферического внимания и знакомитесь с состоянием осознанности.",
      integration: "Используете периферическое внимание для гармонизации своего состояния в течение дня.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_9",
      day: 9,
      name: "Четыре истины Будды",
      theory: "Основы оригинального учения Будды, без религиозности и пафоса. Анализ жизни исторического Будды. Развенчивание главных мифов.",
      practice: "Продолжаете работать с периферическим вниманием и углубляете состояние осознанности.",
      integration: "Используете периферическое внимание в своей повседневной деятельности и анализируете изменения.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_10",
      day: 10,
      name: "Страдание",
      theory: "Узнаете что на самом деле понимается под страданием в концепции учения Будды. Чем обусловлены все негативные переживания.",
      practice: "Продолжаете расширять свое восприятие и углублять состояние осознанности.",
      integration: "Анализируете свои эмоции в течение дня, и выявляете повторяющиеся паттерны.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_11",
      day: 11,
      name: "Зависимости",
      theory: "Определение понятия зависимостей, или страстных желаний как корня всех негативных переживаний. Отличия умелых и неумелых желаний.",
      practice: "Сами выбираете приемы, пройденные в прошлых сессиях для медитации, для закрепления навыка.",
      integration: "Продолжаете осознавать свои эмоции, но особое внимание обращаете именно на желания и зависимости.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_12",
      day: 12,
      name: "Срединный путь",
      theory: "Разбор Срединного пути как венца учения Будды, с научной точки зрения. Как сохранять баланс во всем.",
      practice: "Продолжаете использовать пройденные приемы, чтобы сформировать собственный, комфортный способ медитации.",
      integration: "Стараетесь сохранять состояние осознанности как можно дольше в течение дня.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_13",
      day: 13,
      name: "Основы нейронауки",
      theory: "Введение в основы строения мозга. Модель развития мозга и деятельности его отделов с точки зрения эволюции.",
      practice: "Продолжаете углублять состояние осознанности с помощью периферического внимания и других техник.",
      integration: "Продолжаете сохранять состояние осознанности в течение дня и используете специальные практики, если понимаете, что блуждаете в мыслях.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_14",
      day: 14,
      name: "Эмоции",
      theory: "Что такое эмоции на самом деле. Зачем они нужны и как они появились. Какие типы эмоций существуют.",
      practice: "Начинаете использовать осознанность собственного тела как эффективный инструмент гармонизации сознания и изменения качества внимания.",
      integration: "Анализируете, как изменяется ваше телесное состояние после практики осознанности.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_15",
      day: 15,
      name: "Физиология эмоций",
      theory: "Как эмоции работают в теле. Автономная нервная система. Как просто и эффективно управлять эмоциональным состоянием.",
      practice: "В свободной форме используете пройденные инструменты, чтобы войти в состояние осознанности, а далее практикуете осознанность тела.",
      integration: "Наблюдаете за телесным компонентом эмоций в течение дня.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_16",
      day: 16,
      name: "Закрепление основ",
      theory: "Повторяем пройденный материал, чтобы закрепить в уме пройденные концепции",
      practice: "Свободная практика. Такие дни нужны для того, чтобы у вас не выработалась привычка медитировать только с сопровождением.",
      integration: "Анализируете свободную практику и инструменты, показавшие максимальную эффективность.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_17",
      day: 17,
      name: "Нравственность",
      theory: "Правильное понятие нравственности. Доброжелательность как основа нравственности. Внутреннее, безусловное счастье и удовлетворенность.",
      practice: "Вводите практику метты - медитацию любящей доброты. Учитесь освобождаться от гнева, обид и развиваете эндогенное счастье.",
      integration: "Пробуете сохранять состояние метты в течение дня и анализируете, как меняется ваше состояние и обстоятельства.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_18",
      day: 18,
      name: "Мудрость",
      theory: "Понимание мудрости как особого режима работы мозга. Два типа мышления. Состояние потока и вдохновения как синонимы мудрости.",
      practice: "Продолжаете практику метты для развития чувства внутреннего счастья и освобождения от гнева и обид.",
      integration: "Пробуете возвращаться в состояние метты каждый раз, когда взаимодействуете с другими людьми.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_19",
      day: 19,
      name: "Свойства вещей",
      theory: "Разбор сложной для понимания темы свойств ментальных концепций, которыми ум пытается описывать реальность.",
      practice: "Продолжаете использовать практику метты для гармонизации своего состояния и развития чувства спокойной, безусловной любви.",
      integration: "Обращаете внимание на непостояноство, как фундаментальное свойство всех вещей, включая людей и самих себя.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_20",
      day: 20,
      name: "Второе свойство",
      theory: "Понятие неудовлетворенности как второго фундаментального свойства всех умственный концепций.",
      practice: "Совмещаете практику метты и практику осознанности, как неотъемлемые компоненты духовной эволюции.",
      integration: "Обращаете внимание на неудовлетворительность, как фундаментальное свойство всех вещей.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_21",
      day: 21,
      name: "Третье свойство",
      theory: "Разбор третьего, и самого сложного для понимания свойства умственных концепций и природы реальности - безличности.",
      practice: "Продолжаете комбинировать практику осознанности и метты.",
      integration: "Обращаете внимание на все три фундаментальных свойства реальности, и анализируете, каким образом меняется ваше отношение к ним.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_22",
      day: 22,
      name: "Загрязнения ума",
      theory: "Введение в 5 основных загрязнений ума - нежелательных ментальных переживаний, лежащих в основе всех страданий.",
      practice: "Практикуете универсальную медитацию, которая является основой вашего дальнейшего пути.",
      integration: "Используете весь день как большую практику осознанности. Стараетесь всегда поддерживать это состояние.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_23",
      day: 23,
      name: "Второе загрязнение",
      theory: "Разбор чувства недоброжедательности. Каким образом оно отравляет жизнь. Почему в нем нет смысла и как от него освободиться.",
      practice: "Узнаете эффективный способ избавления от любых загрязнений ума, и используете его в базовой практике.",
      integration: "Переносите практику осознанности и очищения от загрязнений на свои бытовые дела.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_24",
      day: 24,
      name: "Третье загрязнение",
      theory: "Разбор состояния лени. В чем корень лени и утомленности. Как избежать утомленности ума и тела.",
      practice: "Продолжаете практику развития осознанности и очищения от загрязений ума.",
      integration: "Продолжаете практику осознанности в повседневной жизни.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_25",
      day: 25,
      name: "Четвертое загрязнение",
      theory: "В чем корни тревожности. Классификация тревожности. Как работать с тревожностью и держать ее под контролем. ",
      practice: "Продолжаете практику развития осознанности и очищения от загрязений ума.",
      integration: "Продолжаете практику осознанности в повседневной жизни.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_26",
      day: 26,
      name: "Пятое загрязнение",
      theory: "Что такое разочаровывающие сомнения, или неуверенность. В чем корни этого состояния. Как правильно работать с неуверенностью.",
      practice: "Продолжаете практику развития осознанности и очищения от загрязений ума.",
      integration: "Продолжаете практику осознанности в повседневной жизни.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_27",
      day: 27,
      name: "7 качеств пробуждения",
      theory: "Какие качества сознания необходимо культивировать, чтобы пройти по пути духовной эволюции. Разбор 7 главных составляющих.",
      practice: "Продолжаете практику развития осознанности и очищения от загрязений ума, при этом также обращаете внимание на появления качеств пробужденного сознания.",
      integration: "Продолжаете интеграцию осознанности, при этом обращая внимание на присутствие качеств пробужденного сознания.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_28",
      day: 28,
      name: "Пытливость",
      theory: "Пытливость как постоянное желание ума изучать новое и корректировать собственную картину мира. Почему без этого качества не может быть развития.",
      practice: "Свободная практика.",
      integration: "Развиваете пытливость в течение дня: стараетесь исследовать все состояния собственного ума и тела, и изучать объективную реальность.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_29",
      day: 29,
      name: "Энергичность",
      theory: "Что такое энергичность и как сделать ее постоянным качеством собственной личности.",
      practice: "Самостотельная практика для поиска комфортного стиля медитации.",
      integration: "Анализируете источники дофамина в течение дня, и разделяете из на полезные и вредные.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_30",
      day: 30,
      name: "Удовлетворенность",
      theory: "Понятие удовлетворенности как постоянного, ровного и спокойного состояния ума, независящего от внешних обстоятельств.",
      practice: "Продолжаете самостотельную практику для поиска собственного, комфортного стиля медитации.",
      integration: "Используете внутреннюю радость и гармонию для освобождения от действия вредных желаний.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_31",
      day: 31,
      name: "Безмятежность",
      theory: "Как достичь состояния гармонии и спокойствия. Как сохранять спокойствие в любых жизненных ситуациях и почему в стрессах нет никакой пользы.",
      practice: "Продолжаете самостоятельную практику, но с акцентом на осознанность тела.",
      integration: "Обращаете внимание на беспокойство в течение дня и используете специальную практику очищения.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_32",
      day: 32,
      name: "Собранность",
      theory: "Собранность как особое, сконцентрированное состояние сознания. Как освободитьс от фрагментированного мышления.",
      practice: "Продолжаете самостоятельную практику с акцентом на осознанность тела.",
      integration: "Обращаете внимание на состояние собранности или, наоборот, рассеянности ума, и стараетесь постоянно сохранять концентрацию.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_33",
      day: 33,
      name: "Непоколебимость",
      theory: "Что такое непоколебимость, и почему этого состояния не достичь силой воли или ограничениями.",
      practice: "Продолжаете самостоятельную практику с акцентом на осознанность тела.",
      integration: "Стараетесь отмечать все качества пробуждения в своем уме в течение дня, а также сохранять осознанность.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_34",
      day: 34,
      name: "Принятие",
      theory: "Принятие как основа всех духовных практик. Почему принятие не имеет ничего общего с пассивностью. Как развить принятие.",
      practice: "Продолжаете самостоятельную практику с акцентом на осознанность тела.",
      integration: "Начинаете развитие полезных реакций ума. Практикуете полное принятие в течение дня.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_35",
      day: 35,
      name: "Метта",
      theory: "Что такое метта, или любящая доброта. Как это состояние помогает максимально эффективно решать конфликты. Почему в гневе и обидах нет практического смысла и как от них освободиться.",
      practice: "Продолжаете самостоятельную практику с акцентом на осознанность тела.",
      integration: "Используете силу доброжелательности как умелую реакцию на гнев или обиду.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_36",
      day: 36,
      name: "Благодарность",
      theory: "Благодарность как корень чувства внутренней теплоты и наполненности. Как благодарность делает нас свободными и освобождает от контроля страстей над нашим поведением.",
      practice: "Продолжаете самостоятельную практику.",
      integration: "Практикуете благодарность как эффективную реакцию ума на подавленность и зависимости.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_37",
      day: 37,
      name: "Ум новичка",
      theory: "Как культивировать максимальную открытость ума и непредвзятость. Почему это состояние в разы улучшает наши творческие способности и помогает быстрее и эффективнее развиваться.",
      practice: "Продолжаете самостоятельную практику.",
      integration: "Практикуете ум новичка в повседнейвной жизни и анализируете, как это сказывается на вашей деятельности.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_38",
      day: 38,
      name: "Терпение",
      theory: "Как воспитать в себе терпение, не основанное на принуждении. Терпение как альтернативный подход к ощущению времени и работе со своими желаниями.",
      practice: "Продолжаете самостоятельную практику.",
      integration: "Практикуете очищение от нетерпеливости, с помощью осознания 3 универсальных свойств реальности.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_39",
      day: 39,
      name: "Неосуждение",
      theory: "Как неосуждение помогает нам очистить ум и взять внимание под контроль. Как неосуждение меняет наш образ мыслей и действий.",
      practice: "Продолжаете самостоятельную практику.",
      integration: "Практикуете неосуждение, ничего не оценивая с точки зрения позитива или негатива.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_40",
      day: 40,
      name: "Начало пути",
      theory: "Подытоживаем пройденный материал и вторую часть пути. Повторяем основы и формируем правильное намерение на дальнейшую практику.",
      practice: "Свободная практика.",
      integration: "Повторяете пройденный материал и анализируете свой прогресс.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_41",
      day: 41,
      name: "Духовная эволюция",
      theory: "Понятие духовной эволюции. Физиология процесса. Почему это является прямой необходимостью в век информационной революции.",
      practice: "Начинаем закреплять 3 базовые практики медитации. Начинаем с практики анапанасати.",
      integration: "Отслеживаете моменты, когда ум начинает блуждать в мыслях, и возвращаете концентрацию с помощью осознанности дыхания.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
  ],
  en:  [
    {
      id: "day_1",
      day: 1,
      name: "Mindfulness",
      theory:
        "Learn the scientific and understandable rationale for the state of mindfulness. How mindfulness changes life, and how to develop this state.",
      practice:
        "Become familiar with mindfulness breathing meditation. You will learn the basic techniques and benefits of meditation.",
      integration: "Analyze your condition before and after meditation, and write down your observations.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_2",
      day: 2,
      name: "Meditation",
      theory: "You will understand the essence of meditation. You will learn about its various types and techniques. You will understand which of them are the most effective.",
      practice: "Continue to develop the basic skill of meditation through mindful breathing, gradually expanding the practice.",
      integration: "Learn to use concentration on the breath to work with negative experiences throughout the day",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_3",
      day: 3,
      name: "Restless Mind",
      theory: "Find out why stress exists. How it works in your body and how to get rid of it.",
      practice: "Continue to practice the basic meditation skill by lengthening the practice and learning new techniques.",
      integration: "Learn to use concentration in life to deal effectively with stressful situations.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_4",
      day: 4,
      name: "Clear mind",
      theory: "Find out what state of mind you will come to with constant practice of meditation. Scientific substantiation of awareness and collected, harmonious mind.",
      practice: "Slightly lengthening and expanding the practice of mindfulness of breathing. First results.",
      integration: "Combine concentration on breathing and awareness of emotions in order to harmonize your state of mind throughout the day.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_5",
      day: 5,
      name: "Ego",
      theory: "How our brain creates a simplified version of itself. What does it consist of? How it prevents us from enjoying life, and how to free ourselves from ego control",
      practice: "Continue to develop the basic skill of meditation and harmonize the mind with anapanasati meditation",
      integration: "Continue to be aware of your experiences throughout the day, but pay special attention to the work of ego constructions",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_6",
      day: 6,
      name: "Wheel of Samsara",
      theory: "The work of the dopamine system. Find out why the wheel of Samsara is not about rebirth, but about addiction. And why the ego cannot be satisfied.",
      practice: "Continue to build the basic skill of meditation and deepen your concentration with Anapanasati meditation.",
      integration: "Try to maintain a state of balance of mind throughout the day with specific techniques.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_7",
      day: 7,
      name: "Karma",
      theory: "What is the real root cause of our actions? How karma really works, and why there is no esotericism in it.",
      practice: "Continue to develop the basic skill of concentrated meditation through the practice of mindful breathing.",
      integration: "Transfer the control of attention from the breath to your daily affairs, and analyze how circumstances change.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_8",
      day: 8,
      name: "Awakening",
      theory: "The essence of awakening without fluff and esotericism. The scientific foundation of awakening and spiritual evolution.",
      practice: "Expand your practice with peripheral attention and become aware of the state of mindfulness.",
      integration: "Use peripheral attention to harmonize your state throughout the day.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_9",
      day: 9,
      name: "The Four Truths of Buddha",
      theory: "The foundations of the original teachings of the Buddha without religiosity and pathos. Analysis of the life of the historical Buddha. Debunking the main myths.",
      practice: "Continue to work with peripheral attention and deepen the state of awareness.",
      integration: "Use peripheral attention in your daily activities and analyze changes.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_10",
      day: 10,
      name: "Suffering",
      theory: "Learn what is really meant by suffering in the concept of the Buddha's teachings. What causes all negative experiences?",
      practice: "Continue to expand your perception and deepen with state of awareness.",
      integration: "Analyze your emotions throughout the day and identify recurring patterns.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_11",
      day: 11,
      name: "Dependencies",
      theory: "Defining the concept of addiction, or craving as the root of all negative experiences. Differences between skillful and unskillful desires.",
      practice: "You can choose the techniques you learned in past meditation sessions to reinforce the skill.",
      integration: "Continue to be aware of your emotions, but pay special attention to your desires and dependencies.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_12",
      day: 12,
      name: "Middle Way",
      theory: "Analyzing the Middle Way as the crown of the Buddha's teachings, from a scientific point of view. How to maintain balance in everything.",
      practice: "Continue using the techniques you learned to build your own comfortable way of meditating.",
      integration: "Try to maintain a state of mindfulness as long as possible throughout the day.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_13",
      day: 13,
      name: "Fundamentals of Neuroscience",
      theory: "Introduction to the basics of the structure of the brain. Model of development of the brain and the activity of its departments from the point of view of evolution.",
      practice: "Continue to deepen your state of mind with peripheral attention and other techniques.",
      integration: "Continue to maintain a state of awareness throughout the day and use special practices if you notice your thoughts wandering.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_14",
      day: 14,
      name: "Emotions",
      theory: "What are emotions really? Why are they needed and how did they appear? What types of emotions exist?",
      practice: "You begin to use awareness of your own body as an effective tool for harmonizing consciousness and changing the quality of attention.",
      integration: "Analyze how your body state changes after practicing mindfulness.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_15",
      day: 15,
      name: "Physiology of emotions",
      theory: "How emotions work in the body. The autonomic nervous system. How simple and effective it is to manage the emotional state.",
      practice: "Freely use the tools you learned to enter a state of mindfulness, and then practice mindfulness of the body.",
      integration: "Observe the bodily component of your emotions throughout the day.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_16",
      day: 16,
      name: "Anchoring the basics",
      theory: "We repeat the material covered in order to consolidate the concepts learned in our minds",
      practice: "Free practice. These days are needed so that you don't get into the habit of meditating only with accompaniment.",
      integration: "Analyze your free practice and the tools that have shown maximum efficiency.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_17",
      day: 17,
      name: "Morality",
      theory: "The correct concept of morality. Benevolence as the basis of morality. Inner, unconditional happiness and contentment.",
      practice: "Introduce the practice of metta - loving-kindness meditation. Learn to release anger, resentment and develop endogenous happiness.",
      integration: "Try to maintain the metta state throughout the day and analyze how your state and circumstances change.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_18",
      day: 18,
      name: "Wisdom",
      theory: "Understanding wisdom as a special mode of the brain. Two types of thinking. The state of flow and inspiration as synonyms of wisdom.",
      practice: "Continue to practice metta to develop a sense of inner happiness and release anger and resentment.",
      integration: "Try to return to the metta state every time you interact with other people.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_19",
      day: 19,
      name: "Properties of things",
      theory: "An analysis of a difficult to understand topic of the properties of mental concepts with which the mind tries to describe reality.",
      practice: "Continue to use the practice of metta to harmonize your condition and develop a sense of calm, unconditional love.",
      integration: "Pay attention to impermanence as a fundamental property of all things, including people and ourselves.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_20",
      day: 20,
      name: "Second property",
      theory: "The concept is unsatisfied as the second fundamental property of all mental concepts. ",
      practice: "Combine the practice of metta and the practice of mindfulness as integral components of spiritual evolution.",
      integration: "Pay attention to unsatisfactoriness as a fundamental property of all things.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_21",
      day: 21,
      name: "Third property",
      theory: "Analysis of the third and the most difficult to understand property of mental concepts and the nature of reality - impersonality.",
      practice: "Continue to combine the practice of mindfulness and metta.",
      integration: "Pay attention to all three fundamental properties of reality, and analyze how your attitude towards them changes.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_22",
      day: 22,
      name: "Impurities of the mind",
      theory: "An introduction to the 5 major defilements of the mind - unwanted mental experiences that underlie all suffering.",
      practice: "Practice universal meditation, which is the foundation of your journey.",
      integration: "Use the whole day as a great mindfulness practice. Try to maintain this state at all times.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_23",
      day: 23,
      name: "Second pollution",
      theory: "Analysis of the feeling of ill-will. How it poisons life. Why it makes no sense and how to get rid of it.",
      practice: "Learn an effective way to get rid of any mental impurities, and use it in basic practice.",
      integration: "Introduce the practice of mindfulness and purification from impurities into your everyday life.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_24",
      day: 24,
      name: "Third pollution",
      theory: "Analysis of the state of laziness. What is the root of laziness and fatigue? How to avoid fatigue of mind and body.",
      practice: "Continue the practice of developing mindfulness and clearing away the impurities of the mind.",
      integration: "Continue the practice of mindfulness in your daily life.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_25",
      day: 25,
      name: "Fourth pollution",
      theory: "What are the roots of anxiety? Classification of anxiety. How to work with anxiety and keep it under control.",
      practice: "Continue the practice of developing mindfulness and clearing away the impurities of the mind.",
      integration: "Continue the practice of mindfulness in your daily life.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_26",
      day: 26,
      name: "Fifth pollution",
      theory: "What is disappointing doubt, or uncertainty. What are the roots of this state? How to deal with uncertainty correctly.",
      practice: "Continue the practice of developing mindfulness and clearing away the impurities of the mind.",
      integration: "Continue the practice of mindfulness in your daily life.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_27",
      day: 27,
      name: "7 qualities of awakening",
      theory: "What qualities of consciousness need to be cultivated in order to go along the path of spiritual evolution. Analysis of the 7 main components.",
      practice: "Continue the practice of developing awareness and purifying the mind from the impurities, while also paying attention to the appearance of the qualities of awakened consciousness.",
      integration: "Continue the integration of awareness while paying attention to the presence of the qualities of awakened consciousness.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_28",
      day: 28,
      name: "Inquisitiveness",
      theory: "Inquisitiveness as a constant desire of the mind to learn new things and correct its own picture of the world. Why there can be no development without this quality.",
      practice: "Free practice.",
      integration: "Develop curiosity throughout the day: try to explore all states of your own mind and body, and study objective reality.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_29",
      day: 29,
      name: "Vigor",
      theory: "What is energy and how to make it a permanent quality of your own personality.",
      practice: "Self-hotel practice for finding a comfortable style of meditation.",
      integration: "Analyze the sources of dopamine throughout the day, and separate them into useful and harmful ones.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_30",
      day: 30,
      name: "Satisfaction",
      theory: "The concept of contentment as a constant, even and calm state of mind, independent of external circumstances.",
      practice: "Self-help practice to find your own comfortable style of meditation. ",
      integration: "Use your inner joy and harmony to free yourself from the action of harmful desires.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_31",
      day: 31,
      name: "Serenity",
      theory: "How to achieve a state of harmony and tranquility. How to stay calm in any life situation and why there is no benefit in stress.",
      practice: "Continue to practice on your own, but with an emphasis on body awareness.",
      integration: "Pay attention to anxiety throughout the day and use a specific cleansing practice.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_32",
      day: 32,
      name: "Composure",
      theory: "Composure as a special, concentrated state of consciousness. How to free oneself from fragmented thinking.",
      practice: "Continue to practice on your own with an emphasis on body awareness.",
      integration: "Pay attention to the state of concentration or, conversely, absent-mindedness of the mind, and try to constantly maintain concentration.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_33",
      day: 33,
      name: "Steadfastness",
      theory: "What is steadfastness, and why this state cannot be achieved by willpower or limitations.",
      practice: "Continue to practice on your own with an emphasis on body awareness.",
      integration: "Try to notice all the qualities of awakening in your mind during the day, and also stay mindful.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_34",
      day: 34,
      name: "Acceptance",
      theory: "Acceptance as the basis of all spiritual practices. Why acceptance has nothing to do with passivity. How to develop acceptance.",
      practice: "Continue to practice on your own with an emphasis on body awareness.",
      integration: "Begin to develop beneficial mental responses. Practice total acceptance throughout the day.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_35",
      day: 35,
      name: "Metta",
      theory: "What is metta, or loving-kindness? How this state helps to resolve conflicts as effectively as possible. Why there is no practical sense in anger and resentment and how to get rid of them.",
      practice: "Continue to practice on your own with an emphasis on body awareness.",
      integration: "Use the power of goodwill as a skillful response to anger or resentment.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_36",
      day: 36,
      name: "Thanks",
      theory: "Gratitude as the root of feelings of inner warmth and fulfillment. How gratitude makes us free and frees us from the control of passions over our behavior.",
      practice: "Continue to practice on your own.",
      integration: "Practice gratitude as an effective mind response to depression and addiction.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_37",
      day: 37,
      name: "Beginner's Mind",
      theory: "How to cultivate the maximum openness of mind and impartiality. Why this state dramatically improves our creativity and helps to develop faster and more efficiently.",
      practice: "Continue to practice on your own.",
      integration: "Practice the beginner's mind in everyday life and analyze how it affects your performance.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_38",
      day: 38,
      name: "Patience",
      theory: "How to cultivate patience, not based on coercion. Patience as an alternative approach to feeling time and working with your desires.",
      practice: "Continue to practice on your own.",
      integration: "Practice clearing impatience by being aware of the 3 universal properties of reality.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_39",
      day: 39,
      name: "Non-judgment",
      theory: "How non-judgment helps us clear our minds and take control of our attention. How non-judgment changes our way of thinking and acting.",
      practice: "Continue to practice on your own.",
      integration: "Practice non-judgment without evaluating anything positive or negative.",
      theoryMin: 7,
      practiceMin: 10,
      integrationMin: 3,
    },
    {
      id: "day_40",
      day: 40,
      name: "Beginning of the path",
      theory: "We summarize the material covered and the second part of the path. We repeat the basics and form the correct intention for further practice.",
      practice: "Free practice.",
      integration: "Repeat the covered material and the analyzer so you can see your progress. ",
       theoryMin: 7,
       practiceMin: 10,
       integrationMin: 3,
     },
     {
       id: "day_41",
       day: 41,
       name: "Spiritual Evolution",
       theory: "The concept of spiritual evolution. Physiology of the process. Why is it a direct necessity in the age of the information revolution.",
       practice: "We begin to consolidate the 3 basic meditation practices. We begin with the practice of Anapanasati.",
       integration: "Track the moments when the mind starts to wander in the thoughts and bring back concentration with mindful breathing.",
       theoryMin: 7,
       practiceMin: 10,
       integrationMin: 3,
     },
   ],
}
