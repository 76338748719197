import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  fill: currentColor;
`;

export const SvgContainer = ({ className, width, height, children, ...rest }) => (
  <SVG
    className={className}
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
  </SVG>
);

const Play = props => (
  <SvgContainer width={28} height={28} {...props}>
    <path d={'M11.3334 20.0003V8.00033L19.3334 14.0003L11.3334 20.0003ZM14 0.666992C12.2491 0.666992 10.5152 1.01187 8.89757 1.68193C7.2799 2.35199 5.81004 3.33412 4.57193 4.57224C2.07144 7.07272 0.666687 10.4641 0.666687 14.0003C0.666687 17.5365 2.07144 20.9279 4.57193 23.4284C5.81004 24.6665 7.2799 25.6487 8.89757 26.3187C10.5152 26.9888 12.2491 27.3337 14 27.3337C17.5362 27.3337 20.9276 25.9289 23.4281 23.4284C25.9286 20.9279 27.3334 17.5365 27.3334 14.0003C27.3334 12.2494 26.9885 10.5156 26.3184 8.89788C25.6484 7.2802 24.6662 5.81035 23.4281 4.57224C22.19 3.33412 20.7201 2.35199 19.1025 1.68193C17.4848 1.01187 15.751 0.666992 14 0.666992Z'} />
  </SvgContainer>
);

const Pause = props => (
  <SvgContainer width={28} height={28} {...props}>
    <path d={'M18 19.3337H15.3334V8.66699H18V19.3337ZM12.6667 19.3337H10V8.66699H12.6667V19.3337ZM14 0.666992C12.2491 0.666992 10.5152 1.01187 8.89757 1.68193C7.2799 2.35199 5.81004 3.33412 4.57193 4.57224C2.07144 7.07272 0.666687 10.4641 0.666687 14.0003C0.666687 17.5365 2.07144 20.9279 4.57193 23.4284C5.81004 24.6665 7.2799 25.6487 8.89757 26.3187C10.5152 26.9888 12.2491 27.3337 14 27.3337C17.5362 27.3337 20.9276 25.9289 23.4281 23.4284C25.9286 20.9279 27.3334 17.5365 27.3334 14.0003C27.3334 12.2494 26.9885 10.5156 26.3184 8.89788C25.6484 7.2802 24.6662 5.81035 23.4281 4.57224C22.19 3.33412 20.7201 2.35199 19.1025 1.68193C17.4848 1.01187 15.751 0.666992 14 0.666992Z'} />
  </SvgContainer>
);

const Close = props => (
  <SvgContainer width={14} height={14} {...props}>
    <path d={'M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'} />
  </SvgContainer>
);

export default {
  Play,
  Pause,
  Close,
};
