import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { FeatureItem, FeatureItemHeading, FeatureItemScreenshot, Text } from '../../atoms'
import { getLocale } from '../../../../i18n'
import OFFLINE_RU from '../../../assets/images/offline_screenshot_ru.png'
import OFFLINE_EN from '../../../assets/images/offline_screenshot_en.png'

const SCREENSHOTS = {
  ru: OFFLINE_RU,
  en: OFFLINE_EN,
}

const Offline = () => {
  const { t } = useTranslation()

  const renderScreenshot = () => {
    return (
      <ScreenshotCont>
        <FeatureItemScreenshot image={SCREENSHOTS[getLocale()]} />
        <PopupCont>
          <PopupHeading>{t('Offline.popupTitle')}</PopupHeading>
          <PopupText>{t('Offline.popupDesc')}</PopupText>
          <OkText>{t('Offline.popupGot')}</OkText>
        </PopupCont>
      </ScreenshotCont>
    )
  }

  return (
    <FeatureItem screenshot={renderScreenshot()} flip negativeMargin>
      <FeatureItemHeading>{t('Offline.title')}</FeatureItemHeading>
      <Text white>{t('Offline.desc')}</Text>
    </FeatureItem>
  )
}

const ScreenshotCont = styled.div`
  position: relative;
  display: inline-block;
  ${p => p.theme.max('sm')`
    position: relative;
    left: -20px;
  `}
`
const PopupCont = styled.div`
  position: absolute;
  top: 189px;
  left: 50px;
  background: rgba(255, 255, 255, 0.89);
  width: 270px;
  border-radius: 13px;
  box-shadow: 9px 9px 19px rgba(0, 0, 0, 0.3);
  padding: 19px;
  ${p => p.theme.max('md')`
    left: 26px;
  `}
`

const PopupHeading = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  opacity: 0.5;
`

const PopupText = styled(Text)`
  font-size: 13px;
  font-weight: 400;
  opacity: 0.7;
  text-align: center;
  margin-top: 8px;
`

const OkText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
  margin-top: 20px;
  opacity: 0.5;
`

export default Offline
