import React, { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Player } from 'video-react';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired
  };

  render() {
    const { className } = this.props;
    return ReactDOM.createPortal(
      <Fragment>
        <ModalBackdrop onClick={this.props.onClose}/>
        <CSSTransition
          appear
          in
          classNames="modal"
          timeout={600}
        >
          <ModalContainer className={className}>
            <Close onClick={this.props.onClose}>&times;</Close>
            <ModalBody onClick={e => e.stopPropagation()}>
              <VideoPlayer
                playsInline
                autoPlay
                height={'41vw'}
                src={'https://dj6wjgdqr93aw.cloudfront.net/intro.mp4'}
              />
            </ModalBody>
          </ModalContainer>
        </CSSTransition>
      </Fragment>,
      document.body
    );
  }
}

const animations = {
  fromRight: css`
    &.modal-appear {
      transform: translate(100%, 0);
    }
    &.modal-appear-active {
      transform: translate(0, 0);
      transition: all 300ms ease-out;
    }
    &.modal-exit {
      transform: translate(0, 0);
    }
    &.modal-exit-active {
      transform: translate(100%, 0);
      transition: all 300ms ease-in;
    }
  `,
  fromBottom: css`
    &.modal-appear {
      transform: translate(0, 100%);
    }
    &.modal-appear-active {
      transform: translate(0, 0);
      transition: all 300ms ease-out;
    }
    &.modal-exit {
      transform: translate(0, 0);
    }
    &.modal-exit-active {
      transform: translate(0, 100%);
      transition: all 300ms ease-in;
    }
  `,
  fadeIn: css`
    &.modal-appear {
      opacity: 0;
    }
    &.modal-appear-active {
      opacity: 1;
      transition: all 300ms ease-out;
    }
    &.modal-exit {
      opacity: 1;
    }
    &.modal-exit-active {
      opacity: 0;
      transition: all 300ms ease-in;
    }
  `,
  empty: css`
    &.modal-appear {
    }
    &.modal-appear-active {
    }
    &.modal-exit {
    }
    &.modal-exit-active {
    }
  `,
};

const ModalBackdrop = styled.div`
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${p => p.theme.zIndex.modalBg};
  background: ${p => rgba(p.theme.colors.black, 0.69)};
`;

const ModalContainer = styled.div`
  position:fixed;
  display:flex;
  flex-direction: column;
  background: white;
  left: 50%;
  border-radius: 9px;
  top: 50%;
  padding: 1px;
  width: 73vw;
  height: 38.6vw;
  transform: translate(-50%, -50%);
  z-index: ${p => p.theme.zIndex.modal};
  ${p => p.fullScreen && css`
    width: 100vw;
    height: 100vh;
  `}
  ${p => animations[p.animation] || animations.empty}
  ${p => p.theme.max('sm')`
    width: 100%;
    height: 85vh;
    display: flex;
    top: auto;
    flex-direction: column;
    justify-content: center;
    bottom: -5px;
    left: 0;
    transform: translate(0, 0);
  `}
`;

const Close = styled.button`
  position: absolute;
  top: -26px;
  right: -63px;
  background: transparent;
  outline: none;
  border: 0;
  box-shadow: none;
  padding: 7px;
  color: white;
  font-size: 66px;
  z-index: 2;
  cursor: pointer;
  transition: transform .3s ease;
  ${p => p.theme.min('md')`
    &:hover {
      transform: scale(1.1);
    }
  `}
`;

const ModalBody = styled.section`
  position: relative;
  flex-grow: 1;
  {/*overflow-y: scroll;
  -webkit-overflow-scrolling: touch;*/}
`;

const VideoPlayer = styled(Player)`
  width: 73vw;
  height: 38.6vw;
  border-radius: 9px;
  overflow:hidden;
`;

export default Modal;
