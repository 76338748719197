import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FeatureItem, FeatureItemHeading, FeatureItemScreenshot, Text } from '../../atoms'
import { getLocale } from '../../../../i18n'
import SCREENSHOT_RU from '../../../assets/images/tracker_screenshot_ru.png'
import SCREENSHOT_EN from '../../../assets/images/tracker_screenshot_en.png'
import SCREENSHOT_EXTENDED_EN from '../../../assets/images/screenshot_extended_en.png'
import SCREENSHOT_EXTENDED_RU from '../../../assets/images/screenshot_extended_ru.png'
import ARROW_ICON from '../../../assets/images/practice_arrow.png'

const SCREENSHOTS = {
  ru: SCREENSHOT_RU,
  en: SCREENSHOT_EN,
}

const EXTENDED_SCREENSHOTS = {
  ru: SCREENSHOT_EXTENDED_RU,
  en: SCREENSHOT_EXTENDED_EN,
}

const Tracker = () => {
  const { t } = useTranslation()

  const renderScreenshot = () => (
    <Cont>
      <FeatureItemScreenshot image={SCREENSHOTS[getLocale()]} />
      <Extended src={EXTENDED_SCREENSHOTS[getLocale()]} />
      <ArrowIcon src={ARROW_ICON} />
    </Cont>
  );

  return (
    <FeatureItem screenshot={renderScreenshot()} negativeMargin>
      <FeatureItemHeading>{t('Tracker.title')}</FeatureItemHeading>
      <Text white>{t('Tracker.desc')}</Text>
    </FeatureItem>
  )
}

const Cont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${p => p.theme.max('md')`
    transform: scale(0.7);
    margin-top: -20px;
  `}
`

const Extended = styled.img`
  width: 170px;
  margin-left: 26px;
`

const ArrowIcon = styled.img`
  position: absolute;
  top: 40%;
  left: 243px;
  width: 60px;
`

export default Tracker
