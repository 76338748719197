import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Text, SvgIcon } from '../../atoms';
import { withPlayer } from '../../../api/player';

import PLAY_ICON from 'core/assets/images/icons/play_huge.png';
import PAUSE_ICON from 'core/assets/images/icons/pause_huge.png';

class AudioPlayer extends Component {
  play = () => {
    if (this.props.playAudio) {
      this.props.playAudio();
    }
  };

  pause = () => {
    if (this.props.pauseAudio) {
      this.props.pauseAudio();
    }
  };

  hide = () => {
    this.pause();
    if (this.props.hidePlayer) {
      this.props.hidePlayer();
    }
  };

  render() {
    const {
      isPlayerVisible,
      isAudioPlaying,
      currentAudioTitle,
      currentAudioPosition,
      currentAudioDuration,
      audioProgress,
    } = this.props;

    return (
      <Container isVisible={isPlayerVisible}>
        <ProgressWrap>
          <Progress progress={audioProgress} />
        </ProgressWrap>
        <PlayerCont justifyContent={'space-between'} alignItems={'center'}>
          <Flex alignItems={'center'}>
            <Icon
              src={isAudioPlaying ? PAUSE_ICON : PLAY_ICON}
              onClick={isAudioPlaying ? this.pause : this.play}
            />
            <TrackTitle white>
              {currentAudioTitle || 'Без названия'}
            </TrackTitle>
          </Flex>
          <Flex alignItems={'center'}>
            <Time white>
              {`${(currentAudioPosition / 100).toFixed(2)} / ${(currentAudioDuration / 100).toFixed(2)}`}
            </Time>
            <SvgIcon.Close color={'white'} onClick={this.hide} style={{ cursor: 'pointer' }} />
          </Flex>
        </PlayerCont>
      </Container>
    );
  }
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 69px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  transform: translateY(100%);
  transition: transform 350ms;
  ${p => p.isVisible && css`
    transform: translateY(0);
  `};
`;

const ProgressWrap = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.23);
  overflow: hidden;
`;

const Progress = styled.div`
  position: absolute;
  width: ${p => p.progress * 100}%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${p => p.theme.colors.white};
  // transform: translateX(${p => (p.progress * 100 - 100)}%);
  // transition: all 2000ms;
`;

const PlayerCont = styled(Flex)`
  height: 100%;
  padding: 0 40px;
  ${p => p.theme.max('sm')`
    padding: 0 20px;
  `};
`;

const TrackTitle = styled(Text)`
  margin-left: 40px;
  ${p => p.theme.max('sm')`
    margin-left: 20px;
    font-size: 14px;
  `};
`;

const Time = styled(Text)`
  margin-right: 40px;
  ${p => p.theme.max('sm')`
    margin-right: 20px;
    font-size: 14px;
  `};
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export default withPlayer(AudioPlayer);
