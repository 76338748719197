import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Section, SectionHeading, SectionText } from '../../atoms';
import SvgSection from 'core/ui/components/Day/SvgSection';

const Day = ({ t }) => {
  return (
    <CustomSection>
      <SectionHeading white>{t('Day.title')}</SectionHeading>
      <SectionText white>{t('Day.desc')}</SectionText>
      <InnerWrap>
        <InnerCont>
          <SvgSection />
        </InnerCont>
      </InnerWrap>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  padding: 0 0 40px;
  ${p => p.theme.max('sm')`
    padding: 32px 0;
  `};
`;

const InnerWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const InnerCont = styled.div`
  margin: -40px -80px;
  ${p => p.theme.max('md')`
    margin: 0 -40px;
  `}
`;

export default withTranslation()(Day);
