import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { FeatureItem, FeatureItemHeading, Text } from '../../atoms'
import FlowerScreenshot from './FlowerScreenshot'
import { useScrollPercentage } from '../../hooks'

const Progress = ({ scrollTop }) => {
  const section = useRef(null)
  const [scrollPercent] = useScrollPercentage(section, scrollTop)
  const { t } = useTranslation()

  return (
    <FeatureItem screenshot={<FlowerScreenshot scrollPercent={scrollPercent} />} forwardedRef={section}>
      <FeatureItemHeading>{t('Progress.title')}</FeatureItemHeading>
      <Text white>{t('Progress.desc')}</Text>
    </FeatureItem>
  )
}

export default Progress
