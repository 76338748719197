import React from "react"
import styled from "styled-components"
import { useTranslation } from 'react-i18next';

import { Text } from "../../atoms"

const DescItem = ({ item: { heading, text, number }, highlight, left }) => {
  const { t } = useTranslation()
  return (
    <Cont left={left}>
      <Heading isHighlighted={highlight === number} white>
        <span>{number}. </span>
        {t(heading)}
      </Heading>
      <Text white>{t(text)}</Text>
    </Cont>
  )
}

const Cont = styled.div`
  margin-bottom: 43px;
  text-align: ${(p) => (p.left ? "right" : "left")};
`

const Heading = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -6px;
  text-decoration: ${p => p.isHighlighted ? 'underline' : 'none'};
`

export default DescItem
