import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { usePrevious } from '../../helpers'
import { Container } from 'core/ui/atoms'
// import GetAppButton from '../Header/GetAppButton'
import LOGO_H from '../../../assets/images/sati_logo_h.png'
import GRAD from '../../../assets/images/grad.png'

const FixedHeader = ({ scrollTop }) => {
  const [visible, setVisible] = useState(false)
  const prevVisible = usePrevious(visible)

  useEffect(() => {
    if (!prevVisible && scrollTop > window.innerHeight / 2) {
      setVisible(true)
    }
    if (prevVisible && scrollTop < window.innerHeight / 2) {
      setVisible(false)
    }
  }, [scrollTop, prevVisible])

  return (
    <Cont visible={visible}>
      <Container>
        <Grad />
        <Flex>
          <div>
            <Logo src={LOGO_H} />
          </div>
          <div>
            {/* <GetAppButton small /> */}
            {/* <GetAppButton small isApple /> */}
            {/* <GetAppButton isPayment /> */}
          </div>
        </Flex>
      </Container>
    </Cont>
  )
}

const Cont = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .6s ease;
  ${p => p.visible && css`
    opacity: 1;
    visibility: visible;
  `}
  ${p => p.theme.max('sm')`
    height: 60px;
    display: none;
  `}
`

const Grad = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  /* height: 90px; */
  width: 100%;
  background-image: url(${GRAD});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  opacity: 0.66;
  ${p => p.theme.max('sm')`
    opacity: 0.89;
  `}
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  > div {
    display: flex;
    height: 100%;
    align-items: center;
    flex-shrink: 0;
    &:nth-child(1) {
      flex-grow: 1;
    }
    > button:first-of-type {
      margin-right: 6px;
    }
  }
  ${p => p.theme.max('sm')`
    height: 60px;
    > div {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        flex-grow: 1;
        text-align: center;
        > button {
          margin: 0 auto;
        }
      }
    }
  `}
`

const Logo = styled.img`
  position: relative;
  z-index: 13;
  width: 90px;
`

export default FixedHeader
