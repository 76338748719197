import React from "react"
import styled from "styled-components"

const SliderIndicator = ({ current, length }) => (
  <Cont>
    {Array(length)
      .fill()
      .map((_, i) => (
        <Item key={`indicator-${i}`} active={i === current} />
      ))}
  </Cont>
)

const Cont = styled.div`
  margin: 2vh auto 6vh;
  display: flex;
`

const Item = styled.div`
  height: 3px;
  width: 26px;
  border-radius: 3px;
  background: white;
  transition: all 0.3s ease;
  margin: 0 7px;
  cursor: pointer;
  flex-shrink: 0;
  opacity: ${(p) => (p.active ? 1 : 0.3)};
`

export default SliderIndicator
