import React from 'react'
import styled from 'styled-components'
import header_bg from '../../assets/images/curriculum_bg.png'
import header_bg_blur from '../../assets/images/curriculum_bg_blur.png'
import useViewport from '../../ui/responsiveContext';

const Background = ({ scrollTop }) => {
  const { isMobile } = useViewport();
  let opacity = 0
  const scrollHeight = window.innerHeight / 1.5
  if (scrollTop > scrollHeight) {
    opacity = 1
  } else {
    opacity = scrollTop / scrollHeight
  }
  return (
    <Cont>
      <BgImage source={header_bg} opacity={1} />
      {isMobile ? (
        <BgImage source={header_bg_blur} opacity={scrollTop ? 1 : 0} transition />
      ) : (
        <BgImage source={header_bg_blur} opacity={opacity} />
      )}
    </Cont>
  )
}

const Cont = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  transform: translate3d(0,0,0);
`;

const BgImage = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url(${p => p.source});
  background-size: cover;
  background-position: center;
  opacity: ${p => p.opacity};
  transition: opacity ${p => p.transition ? 1 : 0}s ease;
  transform: translate3d(0,0,0);
`;

export default Background
