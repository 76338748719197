import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { usePrevious } from '../../helpers';
import { Text } from 'core/ui/atoms'

const SliderItem = ({ text, isVisible }) => {
  const [visible, setVisible] = useState(isVisible);
  const timeout = useRef();

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setVisible(true);
      }, 400);
    } else if (!isVisible && prevIsVisible) {
      setVisible(false);
    }
  }, [isVisible, prevIsVisible])

  return (
    <SlideText visible={visible}>{text}</SlideText>
  )
}

const SlideText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  width: 100%;
  font-size: 36px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  opacity: ${p => p.visible ? 1 : 0};
  transition: all .6s ease;
  ${p => p.theme.max('xs')`
    font-size: 7vw;
  `}
`

export default SliderItem
