import React from "react"

const getPct = (value, radius, offset = 0) => {
  let val = value + offset
  const c = Math.PI * (radius * 2)
  if (val < 0) {
    val = 0
  }
  if (val > 100) {
    val = 100
  }
  return ((100 - val) / 100) * c
}

const CircleChart = ({
  value = 30,
  theoryPercentage = 0.25,
  isNegative,
  ...rest
}) => {
  return (
    <svg
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...rest}
    >
      <linearGradient id="negativeGrad" x1="0" y1="0" x2="1" y2="1">
        <stop offset="39%" stop-color="#ff5f6d"></stop>
        <stop offset="100%" stop-color="#ffc371"></stop>
      </linearGradient>
      <linearGradient id="positiveGrad" x1="0" y1="0" x2="1" y2="1">
        <stop offset="39%" stop-color="#45B649"></stop>
        <stop offset="100%" stop-color="#DCE35B"></stop>
      </linearGradient>
      <circle
        r="90"
        cx="100"
        cy="100"
        strokeWidth={3}
        fill="transparent"
        stroke="rgba(255, 255, 255, 0.3)"
        strokeDasharray="565.48"
        strokeDashoffset="0"
      />
      <circle
        id="bar"
        r="90"
        cx="100"
        strokeWidth={3}
        strokeLinecap="round"
        cy="100"
        stroke={`url(#${isNegative ? 'negativeGrad' : 'positiveGrad'})`}
        fill="transparent"
        strokeDasharray="565.48"
        strokeDashoffset={getPct(value, 90)}
      />
    </svg>
  )
}

export default CircleChart
