import React, { useState}  from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next'

import { Checkbox, Flex, Section, Text, SectionHeading } from '../../atoms';
import GetAppButton from 'core/ui/components/Header/GetAppButton'
import InfinityImage from 'core/assets/images/infinity.svg';

const Tiers = () => {
  const { t } = useTranslation();

  const [tier, setTier] = useState();
	const [isPolicyChecked, setIsPolicyChecked] = useState(false);

	const togglePolicy = () => {
		setIsPolicyChecked(st => !st);
	}

    const tierItems = [
			{
				id: 1,
				title: t('Tiers.tierTitle1'),
				subText: 'Ежемесячная оплата',
				oldAmount: 379,
				// amount: 199,
				amount: 379,
				product: 'subscription1month',
				days: 30
			},
			{
				id: 2,
				title: t('Tiers.tierTitle2'),
				subText: 'Оплата раз в 3 месяца',
				oldAmount: 799,
				// amount: 499,
				amount: 799,
				product: 'subscription3month',
				days: 90
			},
			{
				id: 3,
				title: t('Tiers.tierTitle3'),
				subText: 'Оплата раз в год',
				oldAmount: 1790,
				// amount: 1399,
				amount: 1790,
				product: 'subscription1year',
				days: 360
			},
			// {
			// 	id: 4,
			// 	title: t('Tiers.tierTitle4'),
			// 	subText: 'Разовое списание',
			// 	name: 'Cielo Infinity',
			// 	oldAmount: 3999,
			// 	// amount: 2799,
			// 	amount: 3999,
			// 	product: 'subscriptionforever',
			// 	days: 9999
			// }
		];

		const onSelect = (t) => {
			setTier(t);
			setTimeout(() => {
				if (window && window.scrollTo) {
					window.scrollTo({ left: 0, top: document?.body?.scrollHeight, behavior: 'smooth' });
				}
			}, 50);
		};

    const getCheckIcon = isActive => {
      return isActive ? (
        <SvgCheck color="#fff" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.6 3.188c.329-.437.241-1.062-.222-1.353a12 12 0 1 0 4.478 5.052c-.233-.495-.843-.657-1.317-.384-.473.273-.631.877-.406 1.376a10.02 10.02 0 1 1-3.947-4.452c.468.283 1.087.199 1.415-.239Z" fill="currentColor"></path><path d="m7.5 12 3.5 3.5L17.5 9" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
        </SvgCheck>
      ) : (
        <SvgCheck color="rgba(0, 0, 0, 0.2)" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.6 3.188c.329-.437.241-1.062-.222-1.353a12 12 0 1 0 4.478 5.052c-.233-.495-.843-.657-1.317-.384-.473.273-.631.877-.406 1.376a10.02 10.02 0 1 1-3.947-4.452c.468.283 1.087.199 1.415-.239Z" fill="currentColor"></path>
        </SvgCheck>
      );
    }

		return (
			<Section id="purchase">
				<TiersWrap direction={'column'}>
        <SectionHeading white>{t('Tiers.title')}</SectionHeading>
					<TiersWrap>
						{tierItems.map((ti, i) => (
							<ItemWrap
								key={i}
								isActive={tier?.id === ti.id}
								onClick={() => onSelect(ti)}
								isLast={i === 3}
							>
								<ItemTextWrap>
									{i === 3 ? (
										<InfinityIcon src={InfinityImage} />
									) : null}
									{i === 3 ? (
										<ItemName>{ti.name}</ItemName>
									) : null}
									<div>
										<ItemText isLast={i === 3}>{ti.title}</ItemText>
										{i === 3 ? (
											<ItemTextSub isLast={i === 3}>{ti.subText}</ItemTextSub>
										) : null}
									</div>
								</ItemTextWrap>
								{/* <ItemPrice isOld>{ti.oldAmount}.00 ₽</ItemPrice> */}
								<ItemPrice isLast={i === 3}>{ti.amount}.00 ₽</ItemPrice>
								<ItemTextSub isLast={i === 3} hideFull>{ti.subText}</ItemTextSub>
                {getCheckIcon(ti.id === tier?.id)}
								{/* <X2><span>x</span>2</X2> */}
							</ItemWrap>
						))}
					</TiersWrap>
				</TiersWrap>
				<BottomContainer>
					<Checkbox checked={isPolicyChecked} onChecked={togglePolicy}>
						<div>
							<PolicyText>{t('Tiers.policyText')}</PolicyText>
							<PolicyLink href='https://help.cielomeditation.ru' target='_blank'>{t('Tiers.purchaseAgreementText2')}</PolicyLink>
						</div>
					</Checkbox>
          <GetAppButton isPayment disabled={!tier || !isPolicyChecked} goal />
          <SupportTextCont>
            <PolicyText>{t('Tiers.supportText')}</PolicyText>
            {' '}
            <PolicyLink href='mailto:support@cielomeditation.ru' target='_blank'>support@cielo.help</PolicyLink>
          </SupportTextCont>
          <PolicyLink href='https://payment.cielomeditation.ru' target='_blank'>{t('Tiers.cancelLink')}</PolicyLink>
					{/* <PolicyText>{t('Tiers.purchaseAgreementText')}</PolicyText>
					<PolicyLink href='https://help.cielomeditation.ru' target='_blank'>{t('Tiers.purchaseAgreementText2')}</PolicyLink> */}
				</BottomContainer>
			</Section>
		);
}

const TiersWrap = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    max-width: 1245px;
    flex-wrap: wrap;
    margin: 0 auto;
		padding-left: -15px;
		padding-right: -15px;
		width: 100%;
    ${p => p.theme.max('md')`
        justify-content: center;
				padding-left: 8px;
				padding-right: 8px;
     `
    }
`;

const ItemWrap = styled(Flex)`
	position: relative;
	padding: 60px 8px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	border: 3px solid ${p => p.isActive ? p.theme.colors.white : 'transparent'};
	cursor: pointer;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(20px);
	border-radius: 30px;
	margin: 0 15px 30px 15px;
	${p => p.isLast && css`
		padding: 35px 8px;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	`}
	&:hover {
		border: 3px solid ${p => p.theme.colors.white};
	}
	${p => p.theme.max('md')`
		margin-bottom: 30px;
	`}
	${p => p.theme.max('sm')`
    margin-left: 0;
		margin-right: 0;
		padding: 20px 8px;
		margin-bottom: 20px;
		${p => p.isLast && css`
			padding: 20px 8px;
			flex-direction: column;
			justify-content: center;
		`}
 `}
`;

const ItemText = styled(Text)`
	font-family: TT Norms, Arial, sans-serif;
	text-align: center;
	color: ${p => p.theme.colors.white};
	padding: 0;
	margin: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 31px;
	width: 270px;
	${p => p.theme.max('sm')`
		font-size: 20px;
		line-height: 26px;
		${p => p.isLast && css`
			display: none;
		`}
	`}
`;

const ItemPrice = styled(Text)`
	font-family: TT Norms, Arial, sans-serif;
	text-align: center;
	vertical-align: top;
	color: ${p => p.theme.colors.white};
	padding: 0;
	margin: 0;
	width: 270px;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 51px;
	${p => p.isLast && css`padding-right: 50px;`};
	${p => p.isOld && css`
		text-decoration: line-through;
		font-size: 26px;
		line-height: 20px;
		margin-top: 12px;
	`}
	${p => p.theme.max('sm')`
		font-size: ${p.isOld ? 22 : 28}px;
		${p => p.isLast && css`padding-right: 0;`}
	`}
`;

const ItemName = styled(Text)`
	font-family: TT Norms, Arial, sans-serif;
	text-align: center;
	color: ${p => p.theme.colors.white};
	padding: 0;
	margin: 0;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 41px;
	margin-right: 10px;
	${p => p.theme.max('sm')`
		font-weight: 400;
		font-size: 24px;
		line-height: 31px;
		margin-right: 0;
	`}
`;

const ItemTextSub = styled(Text)`
	color: ${p => p.theme.colors.white};
	margin: 0;
	display: ${p => p.isLast && p.hideFull ? 'none' : 'block'};
	${p => p.theme.max('sm')`
		display: ${p => p.isLast && !p.hideFull ? 'none' : 'block'};
	`};
`;

const InfinityIcon = styled.img`
	width: 47px;
	height: 22px;
	margin-right: 12px;
	margin-left: 20px;
	${p => p.theme.max('sm')`
		width: 28px;
		height: 13px;
		margin-right: 11px;
		margin-left: 0;
	`}
`;

const ItemTextWrap = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	p {
		width: auto;
	}
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  align-items: center;
`;

const PolicyText = styled(Text)`
  color: ${p => p.theme.colors.grays.light};
	margin: 0;
	display: inline;
	font-size: 16px;
	margin-right: 4px;
`;

const PolicyLink = styled.a`
  color: ${p => p.theme.colors.white};
  text-decoration: none;
  ${p => p.theme.max('sm')`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const SvgCheck = styled.svg`
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
`;

const SupportTextCont = styled.div`
  padding-top: 16px;
  p {
    display: inline;
    font-size: 16px
  }
  a {
    display: inline;
  }
`;

// const X2 = styled.span`
// 	position: absolute;
// 	top: 2px;
// 	right: 20px;
// 	color: ${p => p.theme.colors.danger};
// 	font-size: 36px;
// 	span {
// 		font-size: 32px;
//     margin-right: 2px;
// 	}
// `;

export default Tiers;
