import React from "react"
import styled, { css } from "styled-components"

import { Flex, Box, Section, Container } from "../../atoms"
import TextItem from "./TextItem"
import Chart from "./Chart"

const COLORS = ["#86C7FF", "#FFB1AC", "#9AFFB6"]

const KNOWLEDGE = [
  {
    heading: "Knowledge.scienceTitle",
    text: "Knowledge.scienceDesc",
    color: COLORS[0],
  },
  {
    heading: "Knowledge.zenTitle",
    text: "Knowledge.zenDesc",
    color: COLORS[1],
  },
  {
    heading: "Knowledge.technoTitle",
    text: "Knowledge.technoDesc",
    color: COLORS[2],
  },
]

const Knowledge = () => {
  return (
    <CustomSection>
      <Container>
        <Flex alignItems="center" grid>
          <KnowledgeBox md={6} sm={6} xs={12} padded moveMobile>
            {KNOWLEDGE.map((k, i) => (
              <TextItem item={k} key={`knowledge-${i}`} />
            ))}
          </KnowledgeBox>
          <KnowledgeBox md={6} sm={6} xs={12} padded>
            <Chart knowledge={KNOWLEDGE} />
          </KnowledgeBox>
        </Flex>
      </Container>
    </CustomSection>
  )
}

const CustomSection = styled(Section)`
  padding: 46px 0 60px;
`

const KnowledgeBox = styled(Box)`
  ${(p) =>
    p.moveMobile &&
    css`
      ${(p) => p.theme.max("sm")`
      order: 2;
    `}
    `}
`

export default Knowledge
