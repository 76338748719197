import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import SliderIndicator from "./SliderIndicator"
import SliderItem from "./SliderItem"

const SLIDES = ["slider1", "slider2", "slider3", "slider4"]

const Slider = () => {
  const [current, setCurrent] = useState(0)
  const timeout = useRef()
  const isFirst = useRef(true)
  const { t } = useTranslation()

  useEffect(() => {
    const next = () => {
      clearTimeout(timeout.current)
      if (current < SLIDES.length - 1) {
        setCurrent(current + 1)
      } else {
        setCurrent(0)
      }
    }
    timeout.current = setTimeout(next, isFirst.current ? 5333 : 3333)
    if (isFirst.current) {
      isFirst.current = false
    }
  }, [current])

  return (
    <>
      <Cont>
        {SLIDES.map((text, i) => (
          <SliderItem
            key={`slide-${i}`}
            isVisible={current === i}
            text={t(`Header.${text}`)}
          />
        ))}
      </Cont>
      <SliderIndicator length={SLIDES.length} current={current} />
    </>
  )
}

const Cont = styled.div`
  position: relative;
  width: 560px;
  height: 150px;
  margin-top: 9vh;
  ${(p) => p.theme.max("xs")`
    width: 100%;
  `}
`

export default Slider
