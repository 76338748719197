import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Text } from 'core/ui/atoms';
import ARROW_ICON from '../../../assets/images/practice_arrow.png'

const DayItem = ({ day, name, isLast, period, openDay, selected }) => {
  const { t } = useTranslation();

  const open = () => {
    openDay(day);
  };

  return (
    <Cont onClick={open} selected={selected}>
      {period && <PeriodCont>{t(`Curriculum.${period.title}`)} <span>{period.days || '∞'} {t('html.days')}</span> <Arrow src={ARROW_ICON} /></PeriodCont>}
      <LineCont>
        <Circle onClick={open} />
        <Line />
        {isLast && <ArrowLast  src={ARROW_ICON} />}
      </LineCont>
      <Day onClick={open}>{t('html.day')} {day}</Day>
      <Name onClick={open}>{name}</Name>
    </Cont>
  );
}

const PeriodCont = styled.div`
  position: absolute;
  top: 0px;
  width: 500px;
  font-size: 24px;
  color: white;
  font-weight: 600;
  span {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 200;
  }
  ${p => p.theme.max('sm')`
    font-size: 20px;
  `}
`;

const LineCont = styled.div`
  position: relative;
`;

const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  cursor: pointer;
  ${p => p.theme.max('sm')`
    width: 14px;
    height: 14px;
  `}
`;

const Arrow = styled.img`
  position: relative;
  width: 33px;
  top: 4px;
  left: 10px;
`;

const ArrowLast = styled(Arrow)`
  position: absolute;
  top: 1px;
  left: auto;
  right: -30px;
  width: 23px;
  opacity: 0.5;
`;

const Line = styled.div`
  position: absolute;
  top: 7px;
  left: 16px;
  width: calc(100% - 16px);
  background: rgba(255, 255, 255, 0.13);
  height: 1px;
  ${p => p.theme.max('sm')`
    left: 14px;
    width: calc(100% - 14px);
  `}
`;

const Day = styled(Text)`
  font-size: 16px;
  color: white;
  font-weight: 200;
  margin-top: 33px;
  margin-bottom: 0;
  cursor: pointer;
  padding-right: 50px;
  ${p => p.theme.max('sm')`
    font-size: 14px;
    margin-top: 26px;
    padding-right: 30px;
  `}
`;

const Name = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 3px;
  padding-right: 50px;
  cursor: pointer;
  opacity: 0.7;
  ${p => p.theme.max('sm')`
    font-size: 16px;
    padding-right: 30px;
  `}
`;

const Cont = styled.div`
  position: relative;
  text-align: left;
  background: transparent;
  border: 0;
  flex-shrink: 0;
  padding-top: 70px;
  cursor: pointer;
  &:hover {
    ${Circle} {
      background: rgba(255, 255, 255, 0.66);
    }
    ${Name} {
      opacity: 1;
    }
  }
  ${p => p.selected && css`
    ${Circle} {
      background: white !important;
      border-color: white !important;
    }
    ${Name} {
      opacity: 1;
    }
  `}
  ${p => p.theme.max('sm')`
    padding-top: 50px;
  `}
`;

export default DayItem;
