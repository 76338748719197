import React from 'react';
import styled from 'styled-components';

// import Text from './Text';

const Checkbox = ({ checked, label, onChecked, children }) => {
  const getCheckIcon = isActive => {
    return isActive ? (
      <SvgCheck color="#fff" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6 3.188c.329-.437.241-1.062-.222-1.353a12 12 0 1 0 4.478 5.052c-.233-.495-.843-.657-1.317-.384-.473.273-.631.877-.406 1.376a10.02 10.02 0 1 1-3.947-4.452c.468.283 1.087.199 1.415-.239Z" fill="currentColor"></path><path d="m7.5 12 3.5 3.5L17.5 9" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
      </SvgCheck>
    ) : (
      <SvgCheck color="rgba(0, 0, 0, 0.2)" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6 3.188c.329-.437.241-1.062-.222-1.353a12 12 0 1 0 4.478 5.052c-.233-.495-.843-.657-1.317-.384-.473.273-.631.877-.406 1.376a10.02 10.02 0 1 1-3.947-4.452c.468.283 1.087.199 1.415-.239Z" fill="currentColor"></path>
      </SvgCheck>
    );
  }

  return (
    <CheckBoxCont onClick={onChecked}>
      {getCheckIcon(checked)}
      {children}
      {/* <Text white>{label}</Text> */}
    </CheckBoxCont>
  );
};

const CheckBoxCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
  ${p => p.theme.max('sm')`
    padding-left: 16px;
    padding-right: 16px;
  `}
`;

const SvgCheck = styled.svg`
  color: white;
  margin-right: 12px;
  flex-shrink: 0;
`;

export default Checkbox;
