import styled from 'styled-components';

import Text from './Text';

const SectionText = styled(Text)`
  max-width: 520px;
  text-align: center;
  margin: 10px auto;
  font-size: 16px;
  color: ${p => p.white ? 'white' : p.theme.colors.black};
  ${p => p.theme.max('sm')`
    font-size: 14px;
    padding: 0 ${p => p.theme.general.mobilePadding};
  `}
`;

export default SectionText;
