import React from "react"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"

import { Flex, Text } from "core/ui/atoms"
import { withPlayer } from "../../../api/player"

const PracticeItem = ({
  title,
  src,
  icon,
  playAudio,
  setAudio,
  currentAudioSrc,
}) => {
  const { t } = useTranslation()
  return (
    <Cont>
      <IconCont
        onClick={() => setAudio(src, title, true)}
        isActive={src === currentAudioSrc}
      >
        <Icon src={icon} />
      </IconCont>
      <Title white>{t(title)}</Title>
    </Cont>
  )
}

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 26px;
  ${(p) => p.theme.min("md")`
    padding-right: 7.6vw;
  `}
  &:first-of-type {
    padding-left: 26px;
    ${(p) => p.theme.min("md")`
      padding-left: 8vw;
    `}
  }
`

const IconCont = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "center",
})`
  width: 172px;
  height: 172px;
  border-radius: 120px;
  border: 2px solid transparent;
  margin-bottom: 27px;
  cursor: pointer;
  ${(p) =>
    p.isActive &&
    css`
      border-color: rgba(255, 255, 255, 0.33);
    `};
  ${(p) => p.theme.max("sm")`
    width: 74px;
    height: 74px;
  `};
`

const Icon = styled.img`
  width: 84px;
  height: 84px;
  ${(p) => p.theme.max("sm")`
    width: 52px;
    height: 52px;
  `};
`

const Title = styled(Text)`
  font-size: 22px;
  font-weight: 600;
  line-height: 33.28px;
  margin: 0;
  text-align: center;
  ${(p) => p.theme.max("sm")`
    font-size: 16px;
    line-height: 21px;
  `};
`

export default withPlayer(PracticeItem)
