import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Section, SectionText, SectionHeading, Flex, Container } from "../../atoms"
import Screenshot from './Screenshot';
import SoundModal from './SoundModal';
import DescItem from './DescItem';

const LEFT_CONT = [
    { heading: 'SoundEditor.musicTitle', text: 'SoundEditor.musicDesc', number: 1 },
    { heading: 'SoundEditor.affirmTitle', text: 'SoundEditor.affirmDesc', number: 3  },
    { heading: 'SoundEditor.soundsTitle', text: 'SoundEditor.soundsDesc', number: 5  },
  ];

const RIGHT_CONT = [
    { heading: 'SoundEditor.freedomTitle', text: 'SoundEditor.freedomDesc', number: 2 },
    { heading: 'SoundEditor.binauralTitle', text: 'SoundEditor.binauralDesc', number: 4  },
    { heading: 'SoundEditor.gongTitle', text: 'SoundEditor.gongDesc', number: 6 },
  ];

const SoundEditor = () => {
  const [highlight, setHighlight] = useState(null)
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation()

  let selectedItem = null
  if (selected) {
    selectedItem =
      LEFT_CONT.find(item => item.number === selected) ||
      RIGHT_CONT.find(item => item.number === selected)
  }

  return (
    <Section>
      <SectionHeading white>{t('SoundEditor.title')}</SectionHeading>
      <SectionText white>{t('SoundEditor.text')}</SectionText>
      <Container>
        <Flex alignItems="center">
          <TextCont>
            {LEFT_CONT.map((item, i) => (
              <DescItem key={`left-sound-${i}`} item={item} left highlight={highlight} />
            ))}
          </TextCont>
          <Screenshot setHighlight={setHighlight} setSelected={setSelected} />
          <TextCont>
            {RIGHT_CONT.map((item, i) => (
              <DescItem key={`right-sound-${i}`} item={item} highlight={highlight} />
            ))}
          </TextCont>
        </Flex>
      </Container>
      {selectedItem && <SoundModal item={selectedItem} onClose={() => setSelected(null)} />}
    </Section>
  );
};

const TextCont = styled.div`
  width: 300px;
  ${p => p.theme.max('md')`
    display: none;
  `}
`;

export default SoundEditor;
