import React from 'react';
import styled, { css } from 'styled-components';

import { getLocale } from '../../../../i18n'
import SCREENSHOT_RU from '../../../assets/images/editor_screenshot_ru.png';
import SCREENSHOT_EN from '../../../assets/images/editor_screenshot_en.png';

const SCREENSHOTS_MAP = {
  ru: SCREENSHOT_RU,
  en: SCREENSHOT_EN,
}

const SoundButton = ({ number, setHighlight, setSelected, ...props }) => {
  const onClick = () => {
    if (window.innerWidth < 780) {
      setSelected(number)
    }
  };

  const onMouseEnter = () => {
    setHighlight(number)
  };

  const onMouseLeave = () => {
    setHighlight(null)
  };

  return (
    <Button onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...props}>
      <span>{number}</span>
    </Button>
  );
};

const Screenshot = ({ setHighlight, setSelected }) => {
  return (
    <ScreenshotCont>
      <ScreenshotWrap>
        <ScreenshotImage image={SCREENSHOTS_MAP[getLocale()]}/>
        <SoundButton top={18} number={1} setHighlight={setHighlight} setSelected={setSelected} />
        <SoundButton top={7.9} right number={2} setHighlight={setHighlight} setSelected={setSelected} />
        <SoundButton top={36.6} number={3} setHighlight={setHighlight} setSelected={setSelected} />
        <SoundButton top={27.6} right number={4} setHighlight={setHighlight} setSelected={setSelected} />
        <SoundButton top={62.3} number={5} setHighlight={setHighlight} setSelected={setSelected} />
        <SoundButton top={46.6} right number={6} setHighlight={setHighlight} setSelected={setSelected} />
      </ScreenshotWrap>
    </ScreenshotCont>
  );
};

const ScreenshotImage = styled.div`
  width: 270px;
  height: 590px;
  border-radius: 33px;
  background-image: url(${p => p.image});
  background-size: cover;
  box-shadow: 9px 9px 19px rgba(0, 0, 0, 0.3);
`;

const ScreenshotCont = styled.div`
  flex-grow: 1;
  text-align: center;
  margin: 60px auto;
`;

const ScreenshotWrap = styled.div`
  position: relative;
  display: inline-block;
`;

const SIDE = 39;
const Button = styled.div`
  position: absolute;
  height: ${SIDE}px;
  width: ${SIDE}px;
  box-shadow: 0 0 9px rgba(255, 255, 255, 0.39);
  color: white;
  cursor: pointer;
  top: ${p => p.top}%;
  border-radius: ${SIDE}px;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => css`${p.right ? 'right' : 'left'}: -${SIDE / 2}px;`}
  background: rgba(0, 0, 0, .19);
  ${p => p.theme.max('md')`
    span {
      display: none;
    }
    &:after {
      content: '+';
    }
  `}
`;

export default Screenshot;
