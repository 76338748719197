import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import {getLocale} from '../../../i18n';
import { SUPPORTED_LOCALES } from '../../../i18n/constants';

const LanguagePicker = () => {
  const [selected, setSelected] = useState(getLocale())
  const [isShown, setIsShown] = useState(false)

  const onSelected = async locale => {
    await i18n.changeLanguage(locale, () => {
      setSelected(locale);
      setIsShown(false);
    });
  };

  return (
    <Cont onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <Selected onClick={() => setIsShown(true)}>{selected}</Selected>
      {isShown && (
        <Dropdown>
          {SUPPORTED_LOCALES.map(l => (
            <DropdownItem key={`lang-${l}`} onClick={() => onSelected(l)}>{l}</DropdownItem>
          ))}
        </Dropdown>
      )}
    </Cont>
  );
}

const OPENED_GAP = 59;
const GAP = 9;
const Cont = styled.div`
  position: fixed;
  top: ${GAP}px;
  width: ${OPENED_GAP - GAP * 2}px;
  height: ${OPENED_GAP - GAP * 2}px;
  right: ${GAP}px;
  border-radius: 33px;
  background: rgba(255, 255, 255, 0.19);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Selected = styled.p`
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
`;

const Dropdown = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.93);
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  top: ${OPENED_GAP - GAP * 2}px;
`;

const DropdownItem = styled.p`
  margin: 0;
  padding: 6px 7px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background: white;
  }
`

export default LanguagePicker;
