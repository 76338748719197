import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import { usePrevious } from '../../helpers';

const Review = ({ review: { author, text }, isVisible }) => {
  const [visible, setVisible] = useState(isVisible);
  const timeout = useRef();
  const { t } = useTranslation()

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setVisible(true);
      }, 400);
    } else if (!isVisible && prevIsVisible) {
      setVisible(false);
    }
  }, [isVisible, prevIsVisible])

  return (
    <Cont visible={visible}>
      <Text white>"{t(text)}"</Text>
      <Author>- {t(author)} -</Author>
    </Cont>
  )
}

const Cont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 180px;
  opacity: ${p => p.visible ? 1 : 0};
  transition: all .3s ease;
  ${p => p.theme.max('md')`
    padding: 0 90px;
  `}
  ${p => p.theme.max('sm')`
    padding: 0 20px;
  `}
`;

const Text = styled.p`
  font-size: 26px;
  text-align: center;
  color: white;
  ${p => p.theme.max('md')`
    font-size: 20px;
  `}
  ${p => p.theme.max('sm')`
    font-size: 18px;
  `}
`;

const Author = styled.p`
  text-align: center;
  font-size: 19px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.7);
  ${p => p.theme.max('sm')`
    font-size: 16px;
  `}
`;

export default Review
