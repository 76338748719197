import React, {PureComponent} from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Reviews from './Reviews';
import advatageStep_1 from '../../../assets/images/knowledge_icon.png'
import advatageStep_2 from '../../../assets/images/puzzle_icon.png'
import advatageStep_3 from '../../../assets/images/meditation_icon.png'
import {Flex, Image, Section, Container, Text} from '../../atoms'

class Advantages extends PureComponent  {
    getAdvantageItems = () => {
        const { t } = this.props;
        return [
            {
                image: advatageStep_1,
                text: t('Advantages.first'),
            },
            {
                image: advatageStep_2,
                text: t('Advantages.second'),
            },
            {
                image: advatageStep_3,
                text: t('Advantages.third'),
            }
        ];
    };

    renderPoints = (items) => {
        return items.map((item, idx) => {
            const {image, text} = item;
            return (
                <ItemWrap key={idx}>
                    <AdvantageItemImage src={image} alt={'Advantage image'} />
                    <AdvantageItemText>{text}</AdvantageItemText>
                </ItemWrap>
            )
        });
    };

    render() {
        return (
            <CustomSection>
                <Container>
                    <Reviews />
                    <AdvantagesWrap direction={'column'}>
                    <AdvantagesWrap>
                        {this.renderPoints(this.getAdvantageItems())}
                    </AdvantagesWrap>
                </AdvantagesWrap>
                </Container>
            </CustomSection>
        );
    }
}

const CustomSection = styled(Section)`
  padding: 0;
  margin: 0 auto;
  max-width: 1245px;
  margin-bottom: 90px;
  ${p => p.theme.max('md')`
    margin-bottom: 82px;
 `}
 ${p => p.theme.max('sm')`
    padding: 0 20px;
    margin-bottom: 40px;
 `}
`;

const AdvantagesWrap = styled(Flex)`
    align-items: center;
    justify-content: space-around;
    max-width: 1245px;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    ${p => p.theme.max('md')`
        justify-content: center;
     `
    }
`;

const ItemWrap = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 350px;
    flex-grow: 1;
     ${p => p.theme.max('md')`
        margin-bottom: 50px;
     `}
`;

const AdvantageItemImage = styled(Image)`
    width: 123;
    height: 123px;
    margin-bottom: 26px;
    opacity: 0.5;
    ${p => p.theme.max('xs')`
        width: 100px;
        height: 100px;
    `}
`;

const AdvantageItemText = styled(Text)`
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-decoration: uppercase;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    color: white;
    padding: 0;
    margin: 0;
    width: 270px;
`;


export default withTranslation()(Advantages);
