import styled from 'styled-components'

const ScreenshotImage = styled.div`
  width: 270px;
  height: 590px;
  border-radius: 33px;
  background-image: url(${p => p.image});
  background-size: cover;
  box-shadow: 9px 9px 19px rgba(0, 0, 0, 0.3);
`;

export default ScreenshotImage