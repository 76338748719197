import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Text } from "core/ui/atoms"

const DayText = ({
  currentDay: {
    theory,
    practice,
    integration,
    theoryMin,
    practiceMin,
    integrationMin,
  },
}) => {
  const { t } = useTranslation()

  return (
    <Cont>
      <div>
        <Title>
          {t('Curriculum.theory')} <span>{theoryMin} {t('Curriculum.minutes')}</span>
        </Title>
        <Desc>{theory}</Desc>
      </div>
      <div>
        <Title>
          {t('Curriculum.practice')} <span>{practiceMin} {t('Curriculum.minutes')}</span>
        </Title>
        <Desc>{practice}</Desc>
      </div>
      <div>
        <Title>
          {t('Curriculum.integration')} <span>{integrationMin} {t('Curriculum.minutes')}</span>
        </Title>
        <Desc>{integration}</Desc>
      </div>
    </Cont>
  )
}

const Cont = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    position: relative;
    width: 29%;
    flex-grow: 0;
    flex-shrink: 0;
    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        height: 70px;
        right: -19px;
        width: 1px;
        background: rgba(255, 255, 255, 0.19);
        top: 78px;
      }
    }
  }
  ${(p) => p.theme.max("md")`
    padding: 0 ${(p) => p.theme.general.mobilePadding};
  `}
  ${(p) => p.theme.max("sm")`
    display: block;
    > div {
      width: 100%;
      &:after {
        display: none;
      }
    }
  `}
`

const Title = styled(Text)`
  font-size: 24px;
  color: white;
  font-weight: 600;
  margin-bottom: 16px;
  span {
    font-size: 12px;
    font-weight: 200;
    margin-left: 6px;
  }
  ${(p) => p.theme.max("sm")`
    font-size: 20px;
  `}
`

const Desc = styled(Text)`
  font-size: 16px;
  color: white;
  margin-top: 6px;
`

export default DayText
