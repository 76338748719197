import React from 'react'
import styled from 'styled-components'
import PHONE from '../../../assets/images/phone_ru.png'

const Phone = () => {
  return (
    <Cont>
      <Img src={PHONE} />
    </Cont>
  )
}

const Cont = styled.div`
  display: inline-block;
  border-radius: 33px;
  box-shadow: 9px 9px 19px rgba(0, 0, 0, 0.3);
  ${p => p.theme.max('md')`
    display: none;
  `}
`

const Img = styled.img`
  width: 300px;
`

export default Phone
