import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SectionHeading, SectionText, Container } from 'core/ui/atoms';
import MetricsItem from './MetricsItem';
import { useScrollPercentage } from '../../hooks'

const OFFSET = 200;

const Changes = ({ scrollTop }) => {
  const section = useRef(null)
  const [percent] = useScrollPercentage(section, scrollTop, OFFSET)
  const { t } = useTranslation()

  return (
    <CustomSection ref={section}>
      <SectionHeading white>{t('Changes.title')}</SectionHeading>
      <SectionText white>{t('Changes.desc')}</SectionText>
      <CourseStripe>
        <DayPoint percentage={percent}>
          <span>{t('Changes.day')} {Math.round(percent / 1.5)}</span>
        </DayPoint>
        <Stripe percentage={percent} />
      </CourseStripe>
      <Container>
        <MetricsCont>
          <div>
            <MetricsItem percent={percent} isNegative min={0} max={67} title={t('Changes.negativeOne')} />
          </div>
          <div>
            <MetricsItem percent={percent} isNegative min={0} max={45} title={t('Changes.negativeTwo')} />
          </div>
          <div>
            <MetricsItem percent={percent} isNegative min={0} max={80} title={t('Changes.negativeThree')} />
          </div>
          <div>
            <MetricsItem percent={percent} min={0} max={77} title={t('Changes.positiveOne')} />
          </div>
          <div>
            <MetricsItem percent={percent} min={0} max={100} title={t('Changes.positiveTwo')} />
          </div>
          <div>
            <MetricsItem percent={percent} min={0} max={92} title={t('Changes.positiveThree')} />
          </div>
        </MetricsCont>
      </Container>
    </CustomSection>
  );
}

const CustomSection = styled.div`
  position: relative;
  padding: 60px 0;
`;

const MetricsCont = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  ${p => p.theme.max('md')`
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  `}
`;

const CourseStripe = styled.div`
  position: relative;
  width: 100vw;
  margin-top: 70px;
  margin-bottom: 60px;
  ${p => p.theme.max('sm')`
    margin-top: 50px;
    margin-bottom: 40px;
  `}
`;

const Stripe = styled.div`
  position: relative;
  height: 3px;
  width: 100%;
  background: rgba(255, 255, 255, 0.23);
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    transform: translate(${p => p.percentage}%, 0);
    background: white;
  }
`;

const DayPoint = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: -3px;
  border-radius: 10px;
  background: white;
  left: calc(${p => p.percentage}vw - 2px);
  span {
    position: absolute;
    width: 100px;
    top: -30px;
    font-size: 14px;
    color: white;
    text-align: center;
    transform: translate(-50%, 0);
  }
`

export default Changes;
