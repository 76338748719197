import React, { useRef } from 'react'
import { useTranslation } from "react-i18next"

import { FeatureItem, FeatureItemHeading, Text } from '../../atoms'
import { useScrollPercentage } from '../../hooks'
import HistoryScreenshot from './HistoryScreenshot'

const History = ({ scrollTop }) => {
  const { t } = useTranslation()
  const section = useRef(null)
  const [scrollPercent] = useScrollPercentage(section, scrollTop, 200)

  return (
    <FeatureItem forwardedRef={section} screenshot={<HistoryScreenshot scrollPercent={scrollPercent} />} negativeMargin>
      <FeatureItemHeading>{t('History.title')}</FeatureItemHeading>
      <Text white>{t('History.desc')}</Text>
    </FeatureItem>
  )
}

export default History
