import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

import { FeatureItemScreenshot } from "core/ui/atoms"
import { getLocale } from "../../../../i18n"
import SCREENSHOT_EN from "../../../assets/images/flower_screenshot_en.png"
import SCREENSHOT_RU from "../../../assets/images/flower_screenshot_ru.png"

const SCREENSHOTS = {
  en: SCREENSHOT_EN,
  ru: SCREENSHOT_RU,
}

const CIRCLE_HUGE_DIAMETER = 250
const CIRCLE_SMALL_DIAMETER = CIRCLE_HUGE_DIAMETER / 3
const CIRCLE_BORDER_WIDTH = 1

const radius = CIRCLE_SMALL_DIAMETER / 2 - CIRCLE_BORDER_WIDTH / 2
const xOffset = 0.87
const flower = [
  { x: 0, y: 0, koeff: 0.33 },
  { x: 0, y: -radius, koeff: 0.13 },
  { x: radius * xOffset, y: -radius / 2, koeff: 0.16 },
  { x: radius * xOffset, y: radius / 2, koeff: 0.23 },
  { x: 0, y: radius, koeff: 0.09 },
  { x: -radius * xOffset, y: radius / 2, koeff: 0.15 },
  { x: -radius * xOffset, y: -radius / 2, koeff: 0.23 },
  { x: 0, y: -radius * 2, koeff: 0.04 },
  { x: radius * 2 * xOffset, y: (-radius * 2) / 2, koeff: 0.17 },
  { x: radius * 2 * xOffset, y: (radius * 2) / 2, koeff: 0.21 },
  { x: 0, y: radius * 2, koeff: 0.36 },
  { x: -radius * 2 * xOffset, y: (radius * 2) / 2, koeff: 0.11 },
  { x: -radius * 2 * xOffset, y: (-radius * 2) / 2, koeff: 0.19 },
]
const circles = []

function Circle(x, y, koeff) {
  this.x = x + CIRCLE_HUGE_DIAMETER / 3
  this.y = y + CIRCLE_HUGE_DIAMETER / 3
  this.koeff = koeff
}

const createCircles = () => {
  for (let i = 0; i < flower.length; i++) {
    circles.push(new Circle(flower[i].x, flower[i].y, flower[i].koeff))
  }
}

createCircles()

const getOpacity = (i) => {
  if (i === 0) {
    return 1
  } else if (i && i < 7) {
    return 0.8
  } else {
    return 0.6
  }
}

const FlowerScreenshot = ({ scrollPercent }) => {
  return (
    <Cont>
      <FeatureItemScreenshot image={SCREENSHOTS[getLocale()]} />
      <FlowerCont>
        <BigCircle />
        {circles.map((c, i) => {
          const shiftedX = c.x * c.koeff * 6 * (i % 2 === 0 ? -1 : 1)
          const shiftedY = c.y * c.koeff * 6 * (i % 2 === 0 ? -1 : 1)
          let x = c.x - shiftedX
          let y = c.y - shiftedY
          let opacity = 0
          if (scrollPercent > 0 && scrollPercent < 50) {
            const addX = shiftedX * scrollPercent / 50
            const addY = shiftedY * scrollPercent / 50
            x = x + addX
            y = y + addY
            opacity = 1 * scrollPercent / 50
          } else if (scrollPercent > 50) {
            x = c.x
            y = c.y
            opacity = 1
          }
          return <SmallCircle x={x} y={y} index={i} opacity={opacity} />
        })}
      </FlowerCont>
    </Cont>
  )
}

const Cont = styled.div`
  position: relative;
  display: inline-block;
`
const FlowerCont = styled.div`
  position: absolute;
  top: 36%;
  left: 10px;
  width: ${CIRCLE_HUGE_DIAMETER}px;
  height: ${CIRCLE_HUGE_DIAMETER}px;
`

const BigCircle = styled.div`
  position: absolute;
  width: ${CIRCLE_HUGE_DIAMETER}px;
  height: ${CIRCLE_HUGE_DIAMETER}px;
  border-radius: 1000px;
  border-style: solid;
  border-width: ${CIRCLE_BORDER_WIDTH}px;
  border-color: ${(p) => rgba("white", 0.4)};
  transform: scale(0);
  transition: all 1.8s ease;
  transform-origin: center;
  transition-delay: 1s;
  will-change: transform, opacity;
`

const SmallCircle = styled.div`
  position: absolute;
  width: ${CIRCLE_SMALL_DIAMETER}px;
  height: ${CIRCLE_SMALL_DIAMETER}px;
  border-radius: 300px;
  border-style: solid;
  border-width: ${CIRCLE_BORDER_WIDTH}px;
  border-color: ${(p) => rgba("white", getOpacity(p.index))};
  transform: translate(${(p) => p.x}px, ${(p) => p.y}px) scale(1);
  transform-origin: center;
  will-change: transform, opacity;
  opacity: 1;
`

export default FlowerScreenshot
