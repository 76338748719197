import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { FeatureItem, FeatureItemHeading, Text } from "../../atoms"
import NOTIFICATION_ICON from "../../../assets/images/notification_logo.png"

const NOTIFICATIONS = [
  "Notifications.first",
  "Notifications.second",
  "Notifications.third",
  "Notifications.fourth",
]

const Notifications = () => {
  const { t } = useTranslation()

  const renderScreenshot = () => {
    return NOTIFICATIONS.map((notification, i) => (
      <NotificationCont key={`notif-${i}`}>
        <div>
          <Icon src={NOTIFICATION_ICON} />
          <span>Cielo</span>
        </div>
        <NotificationText>{t(notification)}</NotificationText>
      </NotificationCont>
    ))
  }

  return (
    <FeatureItem screenshot={renderScreenshot()} flip negativeMargin>
      <FeatureItemHeading>{t('Notifications.title')}</FeatureItemHeading>
      <Text white>{t('Notifications.desc')}</Text>
    </FeatureItem>
  )
}

const NotificationCont = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  width: 270px;
  padding: 12px;
  box-shadow: 6px 6px 13px rgba(0, 0, 0, 0.3);
  > div {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: white;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &:nth-child(2) {
    opacity: 0.85;
  }
  &:nth-child(3) {
    opacity: 0.7;
  }
  &:nth-child(4) {
    opacity: 0.55;
  }
`

const NotificationText = styled(Text)`
  font-size: 13px;
  color: white;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0;
  line-height: 1.3;
`

const Icon = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 9px;
  opacity: 0.5;
  border-radius: 6px;
`

export default Notifications
