import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Image, Text } from 'core/ui/atoms'
import satiLogo from "../../../assets/images/sati_logo.png"

const Chart = ({ knowledge }) => {
  const { t } = useTranslation()

  return (
    <Cont>
      <Circle top={-46} left={0} color={knowledge[0].color} />
      <Circle left={-25} top={0} color={knowledge[1].color} />
      <Circle left={25} top={0} color={knowledge[2].color} />
      <HeaderLogo src={satiLogo} alt={'Sati logo'}/>
      <Caption top={3} topXs={17} left={50} leftXs={50}>{t(knowledge[0].heading)}</Caption>
      <Caption top={63} left={13} topXs={63} leftXs={23}>{t(knowledge[1].heading)}</Caption>
      <Caption top={63} left={86} topXs={63} leftXs={79}>{t(knowledge[2].heading)}</Caption>
    </Cont>
  )
}

const SIDE = 330

const Cont = styled.div`
  position: relative;
  width: 420px;
  height: 420px;
  ${p => p.theme.max('sm')`
    width: 100vw;
    height: 100vw;
  `}
  ${p => p.theme.between('sm', 'md')`
    margin-top: 100px;
  `}
`

const Circle = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(${(p) => -50 + p.left}%, ${(p) => -50 + p.top}%);
  width: ${SIDE}px;
  height: ${SIDE}px;
  border-radius: ${SIDE}px;
  background: ${p => p.color};
  opacity: 0.1;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);
  ${p => p.theme.max('sm')`
    width: 60vw;
    height: 60vw;
  `}
`

const Caption = styled(Text)`
  position: absolute;
  top: ${p => p.top}%;
  left: ${p => p.left}%;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  transform: translateX(-50%);
  text-align: center;
  ${(p) => p.theme.max("sm")`
    font-size: 14px;
    top: ${p => p.topXs}%;
    left: ${p => p.leftXs}%;
  `}
`

const HeaderLogo = styled(Image)`
  position: absolute;
  left: 50%;
  top: 46%;
  width: 100px;
  transform: translate(-50%, -50%);
  ${(p) => p.theme.max("md")`
        width: 129px;
        margin-top: 23px;
    `}
    ${(p) => p.theme.max("sm")`
        margin-top: 3vw;
        width: 20vw;
    `}
`

export default Chart
