import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next"

import { Text } from "../../atoms"

const TextItem = ({ item: { heading, text, color } }) => {
  const { t } = useTranslation()

  return (
    <Cont color={color}>
      <Heading>{t(heading)}</Heading>
      <Desc white>{t(text)}</Desc>
    </Cont>
  );
};

const Cont = styled.div`
  position: relative;
  padding-right: 63px;
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  ${p => p.theme.max('md')`
    padding: 0 ${p => p.theme.general.mobilePadding};
  `}
`;

const Heading = styled.h3`
  text-transform: uppercase;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: -4px;
  color: white;
  ${p => p.theme.max('sm')`
    font-size: 18px;
  `}
`;

const Desc = styled(Text)``;

export default TextItem;
