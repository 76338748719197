import styled from 'styled-components'

import Text from './Text'

const FeatureItemHeading = styled(Text)`
  font-size: 33px;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
  ${p => p.theme.max('md')`
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
  `}
`;

export default FeatureItemHeading
