import React from 'react';

import PlayerContext from './context';

export const withPlayer = Component =>
  class Wrapper extends React.Component {
    render() {
      return (
        <PlayerContext.Consumer>
          {value => {
            return <Component {...this.props} {...value} />;
          }}
        </PlayerContext.Consumer>
      );
    }
  };
