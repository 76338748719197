import styled, { css } from 'styled-components';

import { getBorderColor } from 'core/ui/helpers';

export const Input = styled.input`
  display: block;
  border: 0;
  width: 100%;
  border-radius: 100px;
  border: ${props => props.theme.general.borderWidth} solid ${getBorderColor};
  padding: 0.7em 1.5em;
  line-height: 1.7;
  background: transparent;
  font-weight: 300;
  :read-only {
    border-color: ${p => p.theme.colors.grays.light};
    color: ${p => p.theme.colors.grays.regular};
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${p => p.theme.colors.grays.regular};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${p => p.theme.colors.grays.regular};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${p => p.theme.colors.grays.regular};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${p => p.theme.colors.grays.regular};
  }
  ${p =>
    p.error &&
    css`
      border-color: ${p => p.theme.colors.red};
    `};
`;

export default Input;
