export const useScrollPercentage = (element, scrollTop, offset = 0) => {
  let percent = 0
  if (!element.current) {
    return [percent]
  }
  const topPosition = element.current.getBoundingClientRect().top + scrollTop
  const scrollHeight = element.current.offsetHeight * 2
  const scrollPosition = scrollTop + window.innerHeight
  if (topPosition + offset < scrollPosition && topPosition + scrollHeight + offset > scrollPosition) {
    percent = (scrollPosition - topPosition - offset) / scrollHeight * 100
  } else if (topPosition + scrollHeight + offset < scrollPosition) {
    percent = 100
  }

  return [percent];
}
