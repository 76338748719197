import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import {
  Section,
  SectionHeading,
  SectionText,
  Container,
  Text,
} from "../../atoms"
import DayItem from "./DayItem"
import DayText from "./DayText"
import { DAYS } from "./days"
import { useScrollPercentage } from '../../hooks'
import { getLocale } from "../../../../i18n"

const PERIOD = {
  1: {
    title: "period1",
    days: 7,
  },
  7: {
    title: "period2",
    days: 33,
  },
  40: {
    title: "period3",
    days: null,
  },
}

const Curriculum = ({ scrollTop }) => {
  const [currentDay, setCurrentDay] = useState(1)
  const [scrolled, setScrolled] = useState(false)
  const scrollCont = useRef(null)
  const { t } = useTranslation()
  const [scrollPercent] = useScrollPercentage(scrollCont, scrollTop, 200)

  useEffect(() => {
    if (!scrolled) {
      const left = 100 - scrollPercent
      scrollCont.current.scrollTo({
        top: 0,
        left: left / 100 * (window.innerWidth / 2),
        behavior: 'smooth'
      });
      if (scrollPercent > 90) {
        setScrolled(true)
      }
    }
  }, [scrollTop, scrollCont, scrollPercent, scrolled])
  const openDay = (day) => {
    setCurrentDay(day)
  }

  const daysList = DAYS[getLocale()]
  const currentDayData = daysList[currentDay - 1]

  return (
    <CustomSection>
      <Container>
        <SectionHeading white>{t('Curriculum.title')}</SectionHeading>
        <SectionText white>{t('Curriculum.desc')}</SectionText>
      </Container>
      <HorizontalScroll ref={scrollCont} scrollPercent={scrollPercent}>
        {daysList.map((day, i) => (
          <DayItem
            key={day.id}
            {...day}
            openDay={openDay}
            period={PERIOD[day.day]}
            selected={currentDay === day.day}
            isLast={i + 1 === daysList.length}
          />
        ))}
      </HorizontalScroll>
      <Container>
        <DayHeading>
          <span>{t('Curriculum.day')} {currentDay}</span> {currentDayData?.name}
        </DayHeading>
        <DayText currentDay={currentDayData} />
      </Container>
    </CustomSection>
  )
}

const CustomSection = styled(Section)`
  padding: 80px 0;
  margin: 0 auto 0;
  ${(p) => p.theme.max("md")`
    padding: 32px 0;
  `};
`

const HorizontalScroll = styled.div`
  display: inline-flex;
  width: 100%;
  overflow-x: scroll;
  margin-top: 50px;
  margin-bottom: 23px;
  -webkit-overflow-scrolling: touch;
  padding-left: calc((100vw - ${(p) => p.theme.general.containerWidth.lg}) / 2);
  &::-webkit-scrollbar {
    display: none;
  }
  ${p => p.theme.max('md')`
    padding-left: ${p.theme.general.mobilePadding};
  `}
`

const DayHeading = styled(Text)`
  font-size: 32px;
  color: white;
  font-weight: 700;
  margin-bottom: 12px;
  span {
    font-weight: 200;
    margin-right: 6px;
  }
  ${p => p.theme.max('md')`
    font-size: 24px;
    padding: 0 ${p => p.theme.general.mobilePadding};
  `}
`

export default Curriculum
