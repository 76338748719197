import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Flex, Container, Section, Text } from '../../atoms';
import ZEN from '../../../assets/images/yandex_zen.png';
import YOUTUBE from '../../../assets/images/youtube_logo.png';
import INST from '../../../assets/images/inst_logo.png';
import { isAppStoreLanding } from '../../../api/helpers';

const Bottom = () => {
  const { t } = useTranslation();

  return (
    <CustomSection>
      <Container>
        <BottomWrap>
          <LeftCont>
            <LeftText huge>
              Cielo foundation. <strong>{new Date().getFullYear()}</strong>
            </LeftText>
            {/* <LeftText>Microcosm technology inc.</LeftText> */}
            {/* <LeftText>USA, WY, Chyenne</LeftText> */}
            <LeftText>E-mail: support@cielo.help</LeftText>
            {isAppStoreLanding ? (
              <>
                <PolicyLink
                  href={'https://help.cielomeditation.com/#!/tab/741289809-1'}
                  target={'_blank'}
                >
                  <LeftText>{t('privacyPolicy')}</LeftText>
                </PolicyLink>
                <PolicyLink
                  href={'https://help.cielomeditation.com/#!/tab/741289809-2'}
                  target={'_blank'}
                >
                  <LeftText>{t('termsAndConditions')}</LeftText>
                </PolicyLink>
              </>
            ) : null}
          </LeftCont>
          <div>
            <div>
              <a
                href='https://zen.yandex.ru/id/60e46ec137302012e7140800'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon src={ZEN} />
              </a>
              <a
                href='https://www.youtube.com/channel/UCquy8vqzNSaV0n83zq1lbCQ'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon src={YOUTUBE} />
              </a>
              <a
                href='https://www.instagram.com/sati.russia/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon src={INST} />
              </a>
            </div>
          </div>
        </BottomWrap>
      </Container>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  padding: 33px 0 69px;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  ${(p) => p.theme.max('xs')`
    min-height: 60px;
  `}
`;

const BottomWrap = styled(Flex)`
  position: relative;
  width: 100%;
  align-items: center;
  ${(p) => p.theme.max('xs')`
        align-items: flex-start;
        padding: 11px 20px;
        flex-direction: column;
    `}
  > div:nth-child(2) {
    ${(p) => p.theme.max('sm')`
        text-align:center;
        margin-top: 33px;
        width: 100%;
      `}
    a {
      margin: 0 9px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

const LeftCont = styled.div`
  align-items: center;
  flex-grow: 1;
`;

const LeftText = styled(Text)`
  color: white;
  font-size: ${(p) => (p.huge ? 19 : 16)}px;
  opacity: ${(p) => (p.huge ? 1 : 0.69)};
  margin: 3px 0;
  margin-bottom: ${(p) => (p.huge ? 9 : 3)}px;
`;

const Icon = styled.img`
  width: 26px;
  height: 26px;
`;

const PolicyLink = styled.a`
  text-decoration: none;
  margin-top: 10px;
  display: block;
`;

export default Bottom;
