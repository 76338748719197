import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Section, SectionText, SectionHeading, TypeSwitch } from '../../atoms';
import PracticeItem from './PracticeItem';

const typeValues = [
  {
    title: 'Practices.growth',
    value: 'growth',
  },
  {
    title: 'Practices.cleansing',
    value: 'cleanup',
  },
];

const PRACTICES = {
  cleanup: [
    {
      title: 'Practices.passions',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/sea.mp3',
      icon: require('../../../assets/images/icons/passions.png'),
    },
    {
      title: 'Practices.worry',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/jungle.mp3',
      icon: require('../../../assets/images/icons/worry.png'),
    },
    {
      title: 'Practices.idleness',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/light_rain.mp3',
      icon: require('../../../assets/images/icons/sloth.png'),
    },
    {
      title: 'Practices.rage',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/anger.png'),
    },
    {
      title: 'Practices.depression',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/depression.png'),
    },
    {
      title: 'Practices.doubt',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/doubt.png'),
    }
  ],
  growth: [
    {
      title: 'Practices.mindfulness',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/sea.mp3',
      icon: require('../../../assets/images/icons/mindfulness.png'),
    },
    {
      title: 'Practices.kindness',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/jungle.mp3',
      icon: require('../../../assets/images/icons/metta.png'),
    },
    {
      title: 'Practices.wisdom',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/light_rain.mp3',
      icon: require('../../../assets/images/icons/wisdom.png'),
    },
    {
      title: 'Practices.calm',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/peace.png'),
    },
    {
      title: 'Practices.concentration',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/focus.png'),
    },
    {
      title: 'Practices.fulfillment',
      src: 'https://mcrcsm-sati.s3.amazonaws.com/sounds/night.mp3',
      icon: require('../../../assets/images/icons/fulfillment.png'),
    }
  ]
};

const Differences = ({ t }) => {
  const [type, setType] = useState(typeValues[0].value);

  return (
    <CustomSection>
      <SectionHeading white>{t('Practices.title')}</SectionHeading>
      <SectionText white>{t('Practices.text')}</SectionText>
      <TypeSwitchCont>
        <TypeSwitch
          values={typeValues}
          onSelect={setType}
          selectedValue={type}
        />
      </TypeSwitchCont>
      <CarouselContainer>
        <Carousel>
          {(PRACTICES[type] || []).map(p => (
            <PracticeItem
              key={'p-' + p.src}
              {...p}
            />
          ))}
        </Carousel>
      </CarouselContainer>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  padding: 80px 0;
  margin-bottom: 40px;
  ${p => p.theme.max('sm')`
    padding: 40px 0;
  `}
`;

const TypeSwitchCont = styled.div`
  margin-top: 70px;
  margin-bottom: 50px;
  ${p => p.theme.max('sm')`
    margin-top: 55px;
  `}
`;

const CarouselContainer = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Carousel = styled.div`
  display: flex;
`;

export default withTranslation()(Differences);
