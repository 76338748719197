import React from "react"
import styled from "styled-components"
import { Text } from "core/ui/atoms"

import CircleChart from "./CircleChart"

const MetricsItem = ({ percent, title, min, max, isNegative }) => {
  const step = (max - min) / 100

  const value = isNegative
    ? Math.round(max - (step * percent))
    : Math.round(min + (step * percent))

  return (
    <Cont>
      <Circle value={value} isNegative={isNegative} />
      <Value>{value}%</Value>
      <Label>{title}</Label>
    </Cont>
  );
}

const RADIUS = 220;

const Cont = styled.div`
  position: relative;
  text-align: center;
  width: ${RADIUS}px;
  height: ${RADIUS}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${p => p.theme.max('md')`
    width: 43vw;
    height: 43vw;
  `}
`;

const Circle = styled(CircleChart)`
  position: absolute;
  top: 0;
  left: 0;
`

const Value = styled(Text)`
  font-size: 56px;
  font-weight: 200;
  opacity: 0.5;
  margin: 0;
  color: white;
  ${p => p.theme.max('xs')`
    font-size: 39px;
    font-weight: 300;
  `}
`;

const Label = styled(Text)`
  font-size: 16px;
  margin-top: -6px;
  margin: 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
`;

export default MetricsItem
