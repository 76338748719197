import styled, { css } from 'styled-components';
import { hiddenMixin } from 'core/ui/helpers';

export const Button = styled.button`
  background: ${p => p.withBackground ? p.theme.colors.black : p.theme.bg.white};
  color: ${p => p.withBackground ? p.theme.colors.white : p.theme.colors.black};
  border: ${p => p.theme.general.borderWidth} solid ${p => p.theme.colors.black};
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
  font-size: ${p => p.theme.font.size.h4};
  padding: 0.7em 5em;
  text-decoration: none;
  ${p => p.theme.max('sm')`
    padding: 0.9em 3.99em;
    width: 100%;
  `}
  font-weight: bold;
  cursor: pointer;
  will-change: box-shadow;
  transition: box-shadow ${p => p.theme.transitions.fast};
  box-shadow: 0px 20px 15px rgba(255, 40, 39, 0.15);
  &:hover {
    box-shadow: ${p => p.theme.shadows.level1};
  }
  &:disabled {
    border-color: ${p => p.theme.colors.grays.light};
    background-color: ${p => p.theme.colors.grays.light};
  }
  ${p => p.rounded && css`
    border-radius: 100px;
  `}
  ${p => p.huge && css`
    font-size: 16px;
    font-weight: bold;
    padding: 1em 6em;
  `}
  ${p => p.unstyled && css`
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    &:hover {
      box-shadow: none;
    }
  `}
  ${p => p.link && css`
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    font-weight: 300;
    &:hover {
      text-decoration: underline;
      box-shadow: none;
    }
  `}
  ${p => p.default && css`
    background: transparent;
    border: transparent;
    font-weight: normal;
    &:hover {
      box-shadow: none;
    }
  `}
  ${p => p.dangerDot && css`
    position:relative;
    &:after {
      content: "";
      position:absolute;
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${p => p.theme.colors.danger};
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
    }
  `}
  ${p => p.outline && css`
    background: transparent;
    color: ${p => p.theme.colors.black};
    border-color: ${p => p.theme.colors.black};
    &:hover {
      background: ${p => p.theme.colors.black};
      color: white;
    }
  `};
  ${p => p.disabled && css`
    background: ${p => p.theme.colors.grays.light};
    border-color: ${p => p.theme.colors.grays.light};
    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  `}
  ${p => p.black && css`
    background-color: ${p => p.theme.colors.black};
    border-color: ${p => p.theme.colors.black};
    color: white;
  `};
  ${hiddenMixin}
`;

export default Button;
